import axios from 'axios'
import moment from 'moment'
import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import {
  Checkbox,
  Dimmer,
  Dropdown,
  Loader,
  Modal,
  Table
} from 'semantic-ui-react'
import { getSubscription } from '../../actions/home'
import CommonAddCardModal from '../../common/CommonAddCardModal'
import CommonButtons from '../../common/CommonButtons'
import CommonButton from '../../common/CommonButtons'
import CommonInput from '../../common/CommonInput'
import { CommonNotify } from '../../common/CommonNotify'
import { applyChangeSubscription } from '../../config/subscription'
import CloseAccountModal from './CloseAccountModal'
import SubscriptionTable from './SubscriptionTable'
import { Button } from '@mui/material'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'

const amountOptions = [
  {
    key: 1,
    text: '$10',
    value: 10
  },
  {
    key: 2,
    text: '$25',
    value: 25
  },
  {
    key: 3,
    text: '$100',
    value: 100
  },
  {
    key: 4,
    text: '$500',
    value: 500
  },
  {
    key: 5,
    text: '$1000',
    value: 1000
  }
]

class SubscriptionSettings extends Component {
  state = {
    autoTopUp: false,
    accountModal: false,
    autoChargeAmount: 0,
    autoChargeWhen: 0,
    autoTopUp: false,
    isCancelModalOpen: false,
    currentPlan: '',
    creditLeft: 0,
    errorMsg: '',
    addCreditCardLoader: false,
    isTrail: false,
    updateCard: false,
    isLoading: true,
    creditAmount: 0,
    dataModal: {
      dataPlan: {
        planName: 'Pro',
        planPrice: '$119'
      },
      addCredits: '',
      promoCode: '',
      creditCardDetails: {
        cardName: '',
        cardNumber: '',
        validMonth: '',
        validYear: '',
        cvv: ''
      },
      creditCardDetailsErrors: {
        cardName: '',
        cardNumber: '',
        validMonth: '',
        validYear: '',
        cvv: ''
      }
    },
    cardData: null,
    addCardModalOpen: false,
    tables: {
      totalUsage: {
        type: '3',
        header: [
          { headerTitle: 'Call Made To' },
          { headerTitle: 'Time' },
          { headerTitle: 'Credits Used' }
        ],
        tableContentData: [
          {
            columnOne: '+ 71-5676545443',
            columnTwo: '22-05-2019 22:45',
            columnThree: '$ 0.87'
          }
        ]
      },
      subscription: {
        header: [
          // { headerTitle: 'Subscription ID' },
          { headerTitle: 'Product' },
          { headerTitle: 'Current Plan' },
          // { headerTitle: 'Status' },
          { headerTitle: 'Start Date' },
          { headerTitle: 'Renewal Date' },
          { headerTitle: '' },
          { headerTitle: '' }
        ],
        tableContentData: [
          {
            subscriptionId: '',
            product: '',
            currentPlan: '',
            status: '',
            purchase: '',
            ends: '',
            cancel: '',
            upgrade: '',
            isModalOpen: false
          }
        ]
      }
    },
    activeToggle: false,
    activeNewPlan: false,
    newPlanActive: {
      new_plan_start_date: '',
      new_plan: ''
    }
  }

  onAddCreditCard = () => {
    if (
      !this.state.dataModal.creditCardDetails.cardNumber.match(/^[-_ 0-9]+$/)
    ) {
      CommonNotify('Invalid Card No', 'warning')
      return
    }

    this.setState({ addCreditCardLoader: true })

    if (
      !this.state.dataModal.creditCardDetails.cardName ||
      !this.state.dataModal.creditCardDetails.cardNumber ||
      !this.state.dataModal.creditCardDetails.validMonth ||
      !this.state.dataModal.creditCardDetails.validYear ||
      !this.state.dataModal.creditCardDetails.cvv
    ) {
      CommonNotify('Please add all felids', 'warning')
      this.setState({ addCreditCardLoader: false })
      return
    }
    const date = new Date()
    const newYear = moment(date).format('yyyy')
    if (this.state.dataModal.creditCardDetails.validMonth > 12) {
      this.setState({ addCreditCardLoader: false })
      CommonNotify('Please enter valid expire date', 'warning')
      return
    } else {
      if (this.state.dataModal.creditCardDetails.validYear < newYear) {
        this.setState({ addCreditCardLoader: false })
        CommonNotify(
          'Expiry date should not be lesser then current date',
          'warning'
        )
        return
      }
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/create-customer-credit-card`

    const data = {
      name: this.state.dataModal.creditCardDetails.cardName,
      card_number: this.state.dataModal.creditCardDetails.cardNumber,
      expiry: `${this.state.dataModal.creditCardDetails.validMonth}/${this.state.dataModal.creditCardDetails.validYear}`,
      cvv: this.state.dataModal.creditCardDetails.cvv
    }
    axios
      .post(url, data)
      .then(res => {
        this.setState({ addCreditCardLoader: false, newCard: false })
        CommonNotify(
          `Card successfully ${this.state.updateCard ? 'updated' : 'created'}`,
          'success'
        )
        this.setState({ addCardModalOpen: false })
        this.fetchCard()
      })
      .catch(err => {
        const errors = { ...err }
        this.setState({ addCreditCardLoader: false })

        // console.log(" card err", errors.response.data)
        this.setState({ errorMsg: '' })
        if (errors.response.data.errors.length) {
          //CommonNotify(errors.response.data.errors[0])
          const errorMsg = errors.response.data.errors[0]
          this.setState({ errorMsg })
        } else {
          this.setState({ errorMsg: 'Cant create payment methed.' })
          //CommonNotify("Cant create payment methed.")
        }
      })
  }

  onCloseAddCardModal = () => {
    this.setState({ addCardModalOpen: false })
  }

  handleCreditCardInfo = e => {
    const { name, value } = e.target
    const { dataModal } = this.state
    dataModal.creditCardDetails[name] = value

    this.setState({ dataModal })
    const ERROR_CODE = {
      cardName: 'Card Name',
      cardNumber: 'Card Number',
      validMonth: 'Month',
      validYear: 'Year',
      cvv: 'CVV'
    }

    if (!value) {
      dataModal.creditCardDetailsErrors[
        name
      ] = `${ERROR_CODE[name]} is required`
      this.setState({ dataModal })
      return
    } else {
      dataModal.creditCardDetailsErrors[name] = ''
      this.setState({ dataModal })
    }

    if (name === 'cardNumber') {
      if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'validMonth') {
      if (value.toString().length > 2) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 2 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'validYear') {
      if (value.toString().length > 4) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'cvv') {
      if (value.toString().length > 4) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }
  }

  componentDidMount() {
    if (this.props.subscription) {
      this.setState({
        isLoading: false
      })
      const d = this.props.subscription.data
      this.setValueAutoRecharge()
      if (d) {
        this.setState({
          autoTopUp: +d?.auto_charge_status === 0 ? false : true,
          autoChargeAmount: +d?.auto_charge_amount || 0,
          autoChargeWhen: +d?.auto_charge_when || 0
        })
      }

      if (d.new_plan) {
        const newData = {
          new_plan_start_date: d.new_plan_start_date,
          new_plan: d.new_plan
        }
        this.setState({ activeNewPlan: true, newPlanActive: newData })
      }
      if (d != '') {
        const { tables } = this.state
        const subscription = tables.subscription
        const tableContentData = subscription.tableContentData
        const data = []
        const obj = {}

        obj.product = 'LimePhone'
        obj.id = d.id
        obj.subId = d.id
        obj.subscriptionId = d.id
        obj.currentPlan = d.plan_name
        obj.status = this.humanize(d.status)
        obj.ends = d.current_term_end
        obj.starts = d.current_term_start
        obj.max_calls = d.max_calls
        obj.max_sms = d.max_sms
        obj.calls_used = d.calls_used
        obj.sms_used = d.sms_used
        obj.phone_numbers = d.addons.phone_numbers
        let { isTrail } = this.state
        isTrail = true
        if (d.status == 'in_trial') {
          obj.ends = d.trial_end
          isTrail = false
          obj.starts = d.trial_start
        }
        obj.cancel = 'Cancel Subscription'
        obj.upgrade =<Button variant='contained'>Change Plan</Button>
        obj.isModalOpen = false

        let creditLeft = parseFloat(d.credits_in_dollars, 2)

        data.push(obj)
        this.setState(prevState => ({
          ...prevState,
          creditLeft: creditLeft,
          currentPlan: obj.currentPlan,
          isTrail: isTrail,
          tables: {
            ...prevState.tables,
            subscription: {
              ...prevState.tables.subscription,
              tableContentData: data
            }
          }
        }))
      }
    } else {
      this.updateGetSubscription()
    }
  }

  setValueAutoRecharge = () => {
    const values = this.props?.subscription?.data
    if (values) {
      this.setState({
        autoTopUp: +values?.auto_charge_status === 0 ? false : true,
        autoChargeAmount: +values?.auto_charge_amount || 0,
        autoChargeWhen: +values?.auto_charge_when || 0
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.subscription !== prevProps.subscription) {
      this.setState({
        isLoading: false
      })
      this.setValueAutoRecharge()
      const d = this.props.subscription.data
      if (d.new_plan) {
        const newData = {
          new_plan_start_date: d.new_plan_start_date,
          new_plan: d.new_plan
        }
        this.setState({ activeNewPlan: true, newPlanActive: newData })
      }
      if (d != '') {
        const { tables } = this.state
        const subscription = tables.subscription
        const tableContentData = subscription.tableContentData
        const data = []
        const obj = {}
        obj.product = 'LimePhone'
        obj.currentPlan = d.plan_name
        obj.subId = d.id
        obj.status = this.humanize(d.status)
        obj.ends = d.current_term_end
        obj.starts = d.current_term_start
        obj.max_calls = d.max_calls
        obj.max_sms = d.max_sms
        obj.calls_used = d.calls_used
        obj.sms_used = d.sms_used
        obj.phone_numbers = d.addons.phone_numbers
        let { isTrail } = this.state
        isTrail = true
        if (d.status == 'in_trial') {
          obj.ends = d.trial_end
          isTrail = false
          obj.starts = d.trial_start
        }
        obj.cancel = 'Cancel Subscription'
        obj.upgrade =<Button variant='contained'>Change Plan</Button>
        obj.isModalOpen = false

        let creditLeft = parseFloat(d.credits_in_dollars, 2)

        data.push(obj)
        this.setState(prevState => ({
          ...prevState,
          creditLeft: creditLeft,
          currentPlan: obj.currentPlan,
          isTrail: isTrail,
          tables: {
            ...prevState.tables,
            subscription: {
              ...prevState.tables.subscription,
              tableContentData: data
            }
          }
        }))
      }
    }
  }

  setCreditAmount = amount => {
    this.setState({ creditAmount: amount })
  }

  updateGetSubscription = () => {
    this.setState({
      isLoading: true
    })
    this.props.getSubscription()
  }

  integrateGTM = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/integration/get-user-gtm-access`
    const query = window?.location?.href
    const state = decodeURIComponent(query?.split('state=')[1]?.split('&')[0])
    const code = decodeURIComponent(query?.split('code=')[1]?.split('&')[0])
    const scope = decodeURIComponent(
      query?.split('scope=')[1]?.split('&')[0]
    ).replaceAll('+', ' ')
    const authuser = query?.split('authuser=')[1]?.split('&')[0]
    const prompt = query?.split('prompt=')[1]?.split('&')[0]
    var bodyFormData = new FormData()
    bodyFormData.append('state', state)
    bodyFormData.append('code', code)
    bodyFormData.append('scope', scope)
    bodyFormData.append('authuser', authuser)
    bodyFormData.append('prompt', prompt)

    axios
      .post(url, bodyFormData)
      .then(res => {})
      .catch(error => {})
  }
  componentWillMount = () => {
    const query = window?.location?.href
    const code = query?.split('state=')[1]?.split('&')[0]
    if (!!code) {
      this.integrateGTM()
    }

    this.fetchCard()
  }
  handleToggleData = toggleData => {
    this.setState({ activeToggle: toggleData })
  }

  humanize = str => {
    var i,
      frags = str.split('_')
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
  }

  handleAddCreditModal = () => {
    let { isCancelModalOpen, isTrail } = this.state

    if (!this.state.cardData?.id) {
      // console.log(this.state.cardData)

      this.setState({ addCardModalOpen: true })
      return
    } else {
      this.setState({ addCardModalOpen: false })
    }

    isCancelModalOpen = !isCancelModalOpen
    this.setState({ isCancelModalOpen })
  }

  fetchCard = async () => {
    this.setState({
      isLoading: true
    })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-payment-methods`
    axios
      .get(url)
      .then(res => {
        if (res.data.data[0]) {
          this.setState({ cardData: res.data.data[0], isLoading: false })
        }
      })
      .catch(error => {
        this.setState({
          isLoading: false
        })

        if (error.response) {
          // Request made and server responded
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      })
  }

  handleAddCredit = async () => {
    this.setState({
      isLoading: true
    })

    if (this.state.creditAmount < 10) {
      CommonNotify(`The amount must be at least 10$`)
      return
    }

    const data = {
      amount: this.state.creditAmount,
      card: this.state.cardData.id
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/funds`
    axios
      .post(url, data)
      .then(res => {
        this.setState({
          isLoading: false
        })
        CommonNotify(
          `Successfully added ${this.state.creditAmount} to the credit`,
          'success'
        )
        let { isCancelModalOpen } = this.state
        isCancelModalOpen = !isCancelModalOpen
        this.setState({ isCancelModalOpen })
        this.updateGetSubscription()
      })
      .catch(error => {
        this.setState({
          isLoading: false
        })
        CommonNotify(error?.response?.data?.errors?.[0])
      })
  }

  handleCloseCancelModal = () => this.setState({ isCancelModalOpen: false })

  changeSubscription = () => {
    this.setState({ isLoading: true })
    applyChangeSubscription()
      .then(res => {
        if (res.data.data) {
          CommonNotify('Successfully', 'success')
          this.setState({ activeNewPlan: false })
          this.updateGetSubscription()
        }
      })
      .catch(err => {
        const errors = { ...err }
        if (errors.response.data.errors) {
          CommonNotify(errors.response.data.errors[0])
          this.setState({ isLoading: false })
          return
        }
        CommonNotify('Some thing went wrong')
        this.setState({ isLoading: false })
      })
  }

  handleChangeAutoSaveToggle = () => {
    if (this.state?.cardData) {
      this.setState({
        autoTopUp: !this.state.autoTopUp,
        saveBtn: true
      })
    } else {
      CommonNotify(
        "Currently you don't have any payment method, So you can't perforn any action."
      )
      return
    }
  }

  handleValueSelect = async ({ value }, name) => {
    if (this.state?.cardData) {
      if (name) {
        this.setState({
          [name]: value,
          saveBtn: true
        })
      }
    } else {
      CommonNotify(
        "Currently you don't have any payment method, So you can't perforn any action."
      )
      return
    }
    return
  }

  handleUpdateAutoRechargeAPI = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/auto-recharge`
    const payload = {
      auto_charge_status: this.state?.autoTopUp === false ? 0 : 1,
      auto_charge_amount: this.state?.autoChargeAmount,
      auto_charge_when: this.state?.autoChargeWhen
    }
    axios
      .put(url, payload)
      .then(res => {
        this.updateGetSubscription()
        this.setState({
          saveBtn: false
        })
        CommonNotify('Updated Successfully')
      })
      .catch(error => {
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Something went to wrong!'
        )
      })
  }

  openAccountModal = () => {
    this.setState({
      accountModal: !this.state.accountModal
    })
  }
  render() {
    const { tables, isCancelModalOpen } = this.state
    const plan = tables?.subscription?.tableContentData?.[0]?.status
    const trialEnd=tables?.subscription?.tableContentData?.[0]?.ends
    let today = moment(new Date())
    const  trialDays = trialEnd ? moment(trialEnd)?.diff(today, 'days') : 0
    return (
      <>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <div className="billing-subscription available-phone-credits">
          <div className="container-billing">
            <div className="credits">
              <div className="available-credits">
                <p className="title available-phone-credits">
                  Available Phone Credits
                </p>
                <p className="credit-value">
                  {this.state.creditLeft} $
                  <span
                    className="btn-add-credits"
                    onClick={this.handleAddCreditModal}
                  >
                    Add Credits
                  </span>
                </p>
              </div>
            </div>
            <div className="credits">
              <div className="available-credits">
                <p className="title available-phone-credits">Auto Recharge</p>
                <p className="credit-value">
                  <Checkbox
                    checked={this.state?.autoTopUp}
                    style={{ margin: '0 5%' }}
                    toggle
                    onChange={(e, data) => {
                      this.handleChangeAutoSaveToggle()
                    }}
                  />
                </p>
              </div>
              {this.state?.autoTopUp && (
                <>
                  <div className="available-credits">
                    <p className="title sub-title available-phone-credits">
                      Automatically recharge my account when balance below
                    </p>
                    <p className="credit-value">
                      <Dropdown
                        style={{ minWidth: '180px' }}
                        placeholder={'Select Amount'}
                        onChange={(e, result) =>
                          this.handleValueSelect(result, 'autoChargeWhen')
                        }
                        fluid
                        search
                        selection
                        name="autoChargeWhen"
                        value={this.state?.autoChargeWhen}
                        options={amountOptions || []}
                      />
                    </p>
                  </div>
                  <div className="available-credits">
                    <p className="title sub-title available-phone-credits">
                      Recharge with amount
                    </p>
                    <p className="credit-value">
                      <Dropdown
                        style={{ minWidth: '180px' }}
                        placeholder={'Select Amount'}
                        onChange={(e, result) =>
                          this.handleValueSelect(result, 'autoChargeAmount')
                        }
                        fluid
                        search
                        selection
                        name="autoChargeAmount"
                        value={this.state?.autoChargeAmount}
                        options={amountOptions || []}
                      />
                    </p>
                  </div>
                </>
              )}

              {this.state.saveBtn && (
                <div className="available-credits">
                  <p className="title sub-title available-phone-credits"></p>
                  <p className="credit-value">
                    <CommonButton
                      content="Save Changes"
                      background="blue"
                      onClick={this.handleUpdateAutoRechargeAPI}
                      style={{ marginLeft: '15px' }}
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
          {plan === 'In Trial' && (
            <div className="credits current-plan-credit">
              <h4 className="current-plan-title">
                Current Plan : <strong>In Trial</strong>
              </h4>
              <h3 className="current-plan-tag">
                Your Trial will expire in <strong>{trialDays} days</strong>
              </h3>
            </div>
          )}
          <div className="holder-subscription">
            <SubscriptionTable
              dataTable={tables.subscription}
              currentPlan={this.state.currentPlan}
              updateGetSubscription={this.updateGetSubscription}
            />
          </div>
          <div className="text-center">
            <CommonButtons
              content="Close Account"
              background="blur"
              btnClass="btn-modal-style cursor-pointer"
              onClick={this.openAccountModal}
            />
          </div>
          {this.state.accountModal && (
            <CloseAccountModal open={true} onClose={this.openAccountModal} />
          )}

          <Modal
            className="add-credit-modal-wrapper"
            open={isCancelModalOpen}
            onClose={this.handleCloseCancelModal}
          >
            <Modal.Header>
              <p className="cancel-title">Add Credits</p>
              <i
                onClick={this.handleAddCreditModal}
                className="fa fa-times"
                aria-hidden="true"
              ></i>
            </Modal.Header>
            <Modal.Content>
              <div className="modal-content" style={{ padding: '12px 15px' }}>
                <Table style={{ padding: '10px 12px' }}>
                  <Table.Header style={{ padding: '12px 15px' }}>
                    <Table.Row>
                      <Table.HeaderCell>Card Number</Table.HeaderCell>
                      <Table.HeaderCell>Added Date</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body style={{ padding: '10px 12px' }}>
                    {this.state.cardData && (
                      <Table.Row>
                        <Table.Cell>
                          **** {this.state.cardData?.card?.last_four_digits}
                        </Table.Cell>
                        <Table.Cell>
                          {this.state.cardData?.card?.expiry_month} /{' '}
                          {this.state.cardData.card.expiry_year}
                        </Table.Cell>
                        <Table.Cell>
                          {this.state.cardData?.status === 'valid'
                            ? 'Active'
                            : 'Not active'}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
                <div
                  className="add-credit-field"
                  style={{ padding: '12px 15px' }}
                >
                  <CommonInput
                    title="Add Amount"
                    onChange={e => this.setCreditAmount(e.target.value)}
                    placeholder="Add Amount"
                    name="addAmount"
                    type="text"
                  />
                  <CommonButton
                    content="Add"
                    background="blue"
                    btnClass="btn-send"
                    onClick={this.handleAddCredit}
                  />
                </div>
                <CommonButton
                  content="Add"
                  background="blue"
                  btnClass="btn-send-2"
                  onClick={this.handleAddCredit}
                  style={{ marginLeft: '15px', display: 'none' }}
                />
              </div>
            </Modal.Content>
          </Modal>
          <div
            className="credits"
            style={{ marginTop: '15px', width: '100%', marginBottom: '0px' }}
          >
            <div className="p-4 d-flex justify-content-between align-items-center">
              <div>
                <p style={{ fontWeight: 600 }}>Need Help?</p>
                <p>Do you face any issues in billing? We are here to help.</p>
              </div>
              <div>
                <Button
                  startIcon={<QuestionAnswerOutlinedIcon />}
                  variant="outlined"
                >
                  Chat with us
                </Button>
              </div>
            </div>
          </div>
          <CommonAddCardModal
            open={this.state.addCardModalOpen}
            onCloseAddCardModal={this.onCloseAddCardModal}
            handleCreditCardInfo={this.handleCreditCardInfo}
            creditCardDetailsErrors={
              this.state.dataModal.creditCardDetailsErrors
            }
            errorMsg={this.state.errorMsg}
            addCreditCardLoader={this.state.addCreditCardLoader}
            onAddCreditCard={this.onAddCreditCard}
            updateCard={this.state.updateCard}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ subscription: state.home.subscription })

export default connect(mapStateToProps, { getSubscription })(
  SubscriptionSettings
)
