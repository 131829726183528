import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Dropdown, Modal, Button } from 'semantic-ui-react'
import CommonButton from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import questionsIcon from '../../assets/images/questions-icon.png'
import differentIcon from '../../assets/images/different-plan.png'
import moment from 'moment'
import CommonSubscriptionModal from '../../common/CommonSubscriptionModal'
import AppsumoSubscriptionModal from '../../common/AppsumoSubscriptionModal'
import { getSubscription } from '../../actions/home'
import { connect } from 'react-redux'

const resultData = [
  {
    key: 1,
    text: 'I no longer need to track leads.',
    value: 'I no longer need to track leads.'
  },
  {
    key: 2,
    text: 'I am switching to another provider',
    value: 'I am switching to another provider'
  },
  {
    key: 3,
    text: 'This platform is too expensive',
    value: 'This platform is too expensive'
  },
  {
    key: 4,
    text: 'This is not what I expected this platform to do',
    value: 'This is not what I expected this platform to do'
  },
  {
    key: 5,
    text: 'You do not have the feature(s) I need',
    value: 'You do not have the feature(s) I need'
  },
  {
    key: 6,
    text: 'Platform is not user frindly',
    value: 'Platform is not user frindly'
  }
]

function CancelSubscriptionModal({
  onClose,
  open,
  updateGetSubscription,
  getSubscription,
  subscription,
  rowData
}) {
  const isAppsumo = localStorage.getItem('is_appsumo')
  const [formValue, setFormValue] = useState({
    cancellation_reason: ''
  })
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [planInfo, setPlanInfo] = useState({
    plan_name: '',
    pleaseUpgrade: '',
    isTrial: '',
    trialDays: '',
    titleLine: '',
    subscriptionData: '',
    upgradeLine: '',
    accountInfo: '',
    accountButtonText: '',
    loadTitle: false
  })

  useEffect(() => {
    if (subscription) {
      GetSubscribeData(subscription)
    } else {
      getSubscription()
    }
  }, [])

  const GetSubscribeData = async res => {
    let upgradeStatus = false,
      titleLine = '',
      freeplan = '',
      accountInfo = '',
      accountButtonText = '',
      upgradeLine = ''
    const data = res.data

    let trialEndDate = moment(data.trial_end)
    let planEndDate = moment(
      data.current_term_end ? data.current_term_end : null
    )
    let today = moment(new Date())
    let trialDays = trialEndDate.diff(today, 'days')
    let plan_expired = planEndDate.diff(today, 'days')
    let max_calls = data.max_calls
    let used_calls = data.calls_used
    let pleaseUpgrade = ''

    const tableData = {
      tableContentData: [
        {
          plan_name: data.plan_name,
          ends: 0,
          max_calls: data.max_calls,
          calls_used: data.calls_used,
          max_sms: data.max_sms,
          sms_used: data.sms_used
        }
      ]
    }
    //------------------- Trial Plan -------------------//
    //console.log("trial days", trialDays, used_calls, max_calls)

    if (+isAppsumo) {
      titleLine = ' '
    } else {
      if (data.status === 'in_trial') {
        upgradeStatus = true
        if (trialDays > 0) {
          accountInfo = `You are currently on a ${
            data?.plan_name
          } trial plan which will expire on ${moment(data?.trial_end).format(
            'LL'
          )}`
          accountButtonText = 'Upgrade now'
          pleaseUpgrade = 'Upgrade now'
          titleLine =
            'See what your trial is all about. Your trial plan will expire in ' +
            trialDays +
            ' days .'
          upgradeLine = '  for a seamless service.'
        } else {
          accountInfo = `Your trial has expired`
          accountButtonText = 'Pick a plan'
          pleaseUpgrade = 'Please Upgrade '
          titleLine =
            'Your trial plan has expired on ' +
            moment(data.trial_end).format('LL') +
            '.'
          upgradeLine = ' .'
        }
      } else if (data.status === 'Active') {
        if (plan_expired > 0 && max_calls > used_calls) {
          upgradeStatus = false
          titleLine = ''
        } else if (plan_expired > 0 && max_calls <= used_calls) {
          upgradeStatus = false
          titleLine = ''
        } else if (plan_expired <= 0) {
          upgradeStatus = true
          pleaseUpgrade = 'Please Upgrade '
          titleLine =
            'Your current plan ' +
            data.plan_name +
            ' has expired on ' +
            moment(data.current_term_end).format('LL') +
            '.'
          upgradeLine = ' .'
        } else {
          upgradeStatus = false
          titleLine = ''
        }
      } else if (data?.status === 'trial_expired') {
        accountInfo = `Your trial has expired`
        accountButtonText = 'Pick a plan'
        // this.setState({
        //   showSubscriptionModal: true
        // })
      } else if (data?.status?.toLowerCase() === 'cancelled') {
        accountInfo = `You currently have no active subscription`
        accountButtonText = 'Please pick a plan'
        upgradeStatus = false
      } else {
        upgradeStatus = false
        titleLine = ''
      }
    }
    let upgradeButton = '0',
      adminVerify = await localStorage.getItem('admin_verification_status')

    if (adminVerify == '0') {
      upgradeStatus = true
      upgradeButton = '0'
      titleLine = 'Your account is currently pending verification'
    }

    if (data?.plan_name === 'Free') {
      upgradeStatus = false
      freeplan = 'Your account is currently on Free plan'
      accountInfo = `Your account is currently on Free plan`
      accountButtonText = 'Upgrade'
    }

    await localStorage.setItem('plan_name', res.data.plan_name)
    await localStorage.setItem('max_users', res.data.max_users)
    await localStorage.setItem('upgrade_status', upgradeStatus ? '1' : '0')
    await localStorage.setItem('upgrade_title', titleLine)
    await localStorage.setItem('upgrade_button', upgradeButton)
    await localStorage.setItem('freeplan', freeplan)

    setPlanInfo({
      plan_name:
        data.status === 'Cancelled' || data.status === 'in_trial'
          ? ''
          : res.data.plan_name,
      pleaseUpgrade: pleaseUpgrade,
      isTrial: upgradeStatus,
      trialDays: trialDays,
      titleLine: titleLine,
      subscriptionData: tableData,
      upgradeLine: upgradeLine,
      accountInfo: accountInfo,
      accountButtonText: accountButtonText,
      loadTitle: true
    })
  }

  const handleCloseAccount = async () => {
    if (!formValue?.cancellation_reason) {
      CommonNotify('Please select cancel reason')
      return
    }
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/deactivate-subscription`
    const payload = {
      ...formValue
    }
    Axios.post(url, payload)
      .then(res => {
        CommonNotify('Cancelled Successfully', 'success')
        updateGetSubscription()
        onClose()
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Something went to wrong'
        )
      })
  }

  const closeSubscriptionModal = async () => {
    setShowSubscriptionModal(false)
  }

  return (
    <div>
      <Modal
        className="add-credit-modal-wrapper cancel-subscription"
        open={open}
        onClose={onClose}
      >
        <Modal.Header>
          <p className="cancel-title">Cancel Subscription</p>
          <i onClick={onClose} className="fa fa-times" aria-hidden="true"></i>
        </Modal.Header>
        <Modal.Content>
          <div className="modal-content" style={{ padding: '12px 15px' }}>
            <p className="mb-0">
              Are you sure you want to cancel your subscription? You will not
              longer have access to LimePhone features after{' '}
              <span>
                {moment(rowData?.ends ? rowData?.ends : new Date()).format(
                  'MMM DD, YYYY'
                )}
              </span>
            </p>
            {/* <span>{moment().format("MMM DD, YYYY")}</span> */}

            <div className="cancel-message">
              <div className="cancel-message-icon">
                <img src={questionsIcon} alt="" />
              </div>
              <div className="cancel-message-info">
                <h6>Have Issues or Questions</h6>
                <p>
                  Our support team can help you with any problem you might be
                  facing
                </p>
                <Button
                  primary
                  onClick={() =>
                    window.open('https://limecall.com/contact-us/', '_blank')
                  }
                >
                  Contact Us
                </Button>
              </div>
            </div>
            <div className="cancel-message">
              <div className="cancel-message-icon">
                <img src={differentIcon} alt="" />
              </div>
              <div className="cancel-message-info">
                <h6>Try a Different Play?</h6>
                <p>Unsatisfied with your plan? try another</p>
                <Button
                  color="green"
                  onClick={() => setShowSubscriptionModal(true)}
                >
                  Change Plan
                </Button>
              </div>
            </div>
            <div style={{ padding: '12px 15px' }}>
              <label className="default-text input-title">
                Reason for Cancelling
              </label>
              <Dropdown
                style={{ minWidth: '180px' }}
                placeholder="Select Reason"
                onChange={(e, result) =>
                  setFormValue({
                    ...formValue,
                    cancellation_reason: result?.value
                  })
                }
                fluid
                search
                selection
                name="Cancle Reason"
                value={formValue?.cancellation_reason}
                options={resultData}
              />
            </div>
            <div
              className="quicksetup-footer"
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                padding: '5px 12px'
              }}
            >
              <CommonButton
                content="Cancel"
                background="grey"
                onClick={onClose}
                btnClass="mb-2"
              />
              <CommonButton
                content="Cancel Subscription"
                background="blue"
                onClick={handleCloseAccount}
                style={{ marginLeft: '15px' }}
              />
            </div>
          </div>
        </Modal.Content>
        {isAppsumo === 1 || isAppsumo === '1' ? (
          <AppsumoSubscriptionModal
            open={showSubscriptionModal}
            onClose={closeSubscriptionModal}
            currentPlan={planInfo?.plan_name}
            title="Choose Your Plan"
            dataTable={planInfo?.subscriptionData}
          />
        ) : (
          <CommonSubscriptionModal
            open={showSubscriptionModal}
            onClose={closeSubscriptionModal}
            currentPlan={planInfo?.plan_name}
            title="Choose Your Plan"
            dataTable={planInfo?.subscriptionData}
          />
        )}
      </Modal>
    </div>
  )
}
const mapStateToProps = state => ({
  subscription: state.home.subscription
})

export default connect(mapStateToProps, {
  getSubscription
})(CancelSubscriptionModal)
