import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import CloseIcon from '@mui/icons-material/Close'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import { Button, Checkbox, Modal } from 'semantic-ui-react'
import { ReactComponent as ClarityImg } from '../assets/images/clarity.svg'
import Title from '../common/Title'
import { connect, useDispatch } from 'react-redux'
import { SET_FLAG } from '../actions/types'
import stock from '../assets/images/stock.png'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import DialpadIcon from '@mui/icons-material/Dialpad';
import GroupsIcon from '@mui/icons-material/Groups';
import CallIcon from '@mui/icons-material/Call';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DownloadIcon from '@mui/icons-material/Download';
import { getDialer } from '../actions/dialer'
import SendMessageModal from '../common/SendMessageHeader'
import classNames from 'classnames'
import tour from '../assets/images/tour.png'


const defaultStage = {
  invite_member: 0,
  notification_configuration: 0,
  widget_installed: 0,
  incoming_number: 0
}


const sub_def_names = {
  widget_installed: 'Installation instructions'
  // incoming_number: 'Add a number',
  // setup_integration: 'Setup a integration',
}

const title = {
  titleTwo: 'Getting Started With Limephone'
}

const Clarity = ({ getDialer, dialerStatus }) => {

  // useEffect(() => {
  //   if(!window.location.href?.includes('/analytics')){
  //     fetchChecklistStatus()
  //   }
  // }, [])
  const def_names = {

    setup_integration1: {
      mainTitle: 'Activate Your Account',
      title:
        'Experience the power of Limephone for free for 14 days',
      subTitle: 'Start your journey with Limephone and activate your free trial today. Simply sign up, verify your phone number, and answer a verification call from our support team to get started.',
      linkTo: '/settings/personal/notifications',
      videoLink: 'https://youtube.com/embed/eQfnI1wTO0w',
      btnName: <div className='d-flex'>
        <Button variant="contained" color="primary" onClick={() => navigate.push('https://www.limephone.io/verification')}>Request a callback</Button>
        {/* <Button variant="contained" color="primary" >Contact us on live chat</Button> */}

      </div>,
      icon: <ToggleOnIcon />


    },
    business_hour_check: {
      mainTitle: 'Set Up Your Working Hours',
      title: 'Enhance business efficiency by routing calls based on your established business hours.',
      subTitle: 'Specify your business hours to ensure that calls are handled accordingly during business hours and directed to voicemail or an alternative option during off-hours.',
      linkTo: '/settings/company/business_hours',
      videoLink: 'https://youtube.com/embed/4_wNAMrut7M',
      btnName: <div className='d-flex'>
        <Button variant="contained" color="primary" onClick={() => navigate.push("/settings/personal/working_hours")}>Configure personal working hours</Button>

      </div>,
      icon: <QueryBuilderIcon />

    },
    incoming_number1: {
      mainTitle: 'Setup call routing',
      title: 'Ensure the right team members are handling inquiries and converting them into leads.',
      subTitle: 'Direct calls to the most appropriate team members based on your business needs. Configure routing rules to maximize efficiency.',
      linkTo: '/settings/personal/call_forwarding',
      btnName: <Button variant="contained" color="primary" onClick={() => navigate.push('/settings/personal/call_forwarding')}>Configure Call Forwarding</Button>

      ,
      icon: <DialpadIcon />

    },
    incoming_number: {
      mainTitle: 'Set Up Your Virtual Number',
      title: 'Establish a professional presence and streamline your business communications.',
      subTitle: 'Set up call routing and voicemail settings to ensure your calls are handled efficiently.',
      linkTo: '/settings/personal/call_forwarding',
      videoLink: 'https://youtube.com/embed/Td1NkDWary4',
      btnName: <div className='d-flex'> <Button variant="contained" color="primary" onClick={() => navigate.push('/numbers')}>Manage your number</Button>
        <Button variant="contained" color="primary" onClick={() => navigate.push('/numbers')}>Add a number</Button>
      </div>,
      icon: <DialpadIcon />

    },
    invite_your_team_members1: {
      mainTitle: 'Make Your First Call or send SMS',
      title: 'Embrace the freedom of making and receiving calls with your new Limephone number.',
      subTitle: 'Dial a number, send an SMS, or connect with your team members directly from the app.',
      linkTo: '/settings/users',
      btnName: <div className='d-flex'><Button variant="contained" color="primary" onClick={() => openDialer()}>Make a call</Button>
        <Button variant="contained" color="primary" onClick={() => setOpenSms(!openSms)}>Send an SMS</Button>
      </div>,
      icon: <CallIcon />,
      videoLink: 'https://youtube.com/embed/kGK9eHeBNkc'
    },
    invite_your_team_members: {
      mainTitle: 'Invite Your Team',
      title: 'Collabarate with your team and ramp up your customer experience.',
      subTitle: 'Invite your colleagues and team members to join the Limephone family. Share your business number and collaborate seamlessly on calls, messages, and more.',
      btnName: <Button variant="contained" color="primary" onClick={() => navigate('/settings/teams')}>Add team member</Button>,

      icon: <GroupsIcon />,
      videoLink: 'https://youtube.com/embed/DI7VZQaK1rs'

    },

    setup_integration: {
      mainTitle: 'Connect Your Apps',
      title: 'Enhance productivity, optimize workflows, and gain deeper insights into your business operations.',
      subTitle: 'Synchronize your Limephone account with your favorite productivity apps to streamline your workflow. Integrate with calendars, CRM systems, and other essential tools for a seamless experience.',
      linkTo: '/numbers',
      btnName: <Button variant="contained" color="primary" onClick={() => navigate('/settings/integration')}>Check our apps</Button>,
      icon: <AppShortcutIcon />,
      videoLink: 'https://youtube.com/embed/eoNzA3AXlYQ'

    },
    slack_integration: {
      mainTitle: 'Receive calls/text on slack',
      title:
        'Receive real-time call notifications and messages directly in your Slack workspace.',
      subTitle: `Stay connected to your business conversations even when you're away from your desk`,
      linkTo: '/settings/integration',
      btnName: <Link to='/settings/integration'>Link your Limephone account to your Slack workspace </Link>,
      icon: <IntegrationInstructionsIcon />,

    },
    notification_configuration: {
      mainTitle: 'Set Up Your Notifications',
      title: 'Stay connected and informed, ensuring you never miss a crucial call or lead.',
      subTitle: 'Customize your notification preferences to stay informed about important calls, messages, and events. Receive real-time updates on SMS, Email and Slack.',
      linkTo: '/settings/personal/calendars',
      videoLink: 'https://youtube.com/embed/GtBhIQgknMo',
      btnName: <Button variant="contained" color="primary" onClick={() => navigate.push('/settings/personal/notifications')}>Configure your notification</Button>,
      icon: <NotificationsIcon />

    },

    business_hour_check1: {
      mainTitle: 'Add Payment Information',
      title: 'Confirm your plan',
      subTitle: 'To continue using Limephone beyond your free trial, add your payment method securely and manage your subscription preferences seamlessly.',
      linkTo: '/settings/company/business_hours',
      btnName: <Button variant="contained" color="primary" onClick={() => navigate.push('/settings/billing/payment_methods')}>Add payment method</Button>,
      icon: <AccountBalanceIcon />,
      videoLink: 'https://youtube.com/embed/cTgEcm8tjvc',

    },
    integrate_calendar: {
      mainTitle: 'Download LimePhone apps',
      title: `Seamlessly manage your leads, calls, and SMS messages from your mobile devices.`,
      subTitle: 'Stay connected and manage your Limephone account on the go with our mobile apps for iOS and Android.',
      btnName: <div className='d-flex'>
        <Button variant="contained" color="primary" onClick={() => window.open('https://dialer.limephone.io/')}>Web Dialer</Button>
        <Button variant="contained" color="primary" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.limephone&hl=en&gl=US')}>Google Play Store</Button>

      </div>,
      videoLink: 'https://youtube.com/embed/7Gky61Mk5ZQ',
      icon: <DownloadIcon />

    }
    // incoming_number: 'Add a number',
    // setup_integration: 'Setup a integration',
  }
  const [currentStage, setCurrentStage] = useState([])
  const dispatch = useDispatch()
  const [active, setActive] = useState(Object?.keys(def_names)?.[0])
  const navigate = useHistory()
  const [openSms, setOpenSms] = useState(false)
  const [open, setOpen] = useState(false)


  const handleManageSequance = value => {
    if (value && value?.length) {
      let arrayList = value.slice()
      if (value?.[7]) {
        arrayList[1] = value[7]
        arrayList[7] = value[1]
      }
      return arrayList
    } else {
      return value
    }
  }
  const openDialer = () => {
    getDialer(!dialerStatus)
  }


  const fetchChecklistStatus = () => {
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/checklist`
    axios
      .get(url)
      .then(res => {
        let dt = res.data.data
        let flag = false
        let counter = 0

        let cStage = { ...currentStage }
        Object.entries(dt).forEach(item => {
          cStage[item[0]] = item[1]
          if (item[1] === 1 && Object.keys(def_names).includes(item[0])) {
            counter++
          }
        })
        const def_name_keys = Object.keys(def_names)
        const temp = Object.entries(cStage)?.filter(([k, v]) => {
          return def_name_keys.includes(k)
        })
        temp.push(temp.shift())
        setCurrentStage(temp)
      })
      .catch(err => { })
  }
  useEffect(() => {
    fetchChecklistStatus()
  }, [])
  const pages = {
    // business_hour_check: '/settings/company/business_hours',
    widget_installed: '/settings/installation',
    setup_call_forwarding: '/settings/personal/call_forwarding',
    incoming_number: '/addNumbers',
    invite_member: '/settings/users',
    notification_configuration: '/settings/company/notifications',
    personal_hour_check: '/settings/personal/working_hours',
    personalize_widget: '/widgets',
    setup_integration: '/settings/integration/integration',
    integrate_calendar: '/settings/personal/integrations'
  }

  const hoverEvent = () => {
    setActive(true)
  }

  const hoverOutEvent = () => {
    setActive(false)
  }

  const getValue = key => {
    return currentStage
      ? currentStage?.filter(obj => obj?.[0] === key)?.[0]?.[1]
      : 0
  }

  const addFlag = second => {
    dispatch({
      type: SET_FLAG,
      payload: { activeWidget: 1 }
    })
  }
  const handleModalOpen = () => { setOpen(!open) }

  return (
    <>

      <Modal
        className="subscription-modal" style={{ padding: '0px', width: '1265px' }}

        open={open}
        onClose={handleModalOpen}

      >
        <Modal.Header>
          <p className="modal-header">Whats your primary use case with LimePhone?</p>
          <div className='d-flex' style={{ justifyContent: 'space-between', minWidth: '25%', alignItems: 'center' }}>

            <p className='mb-0 ml-2'>Virtual Numbers and texting</p>
            <i
              onClick={handleModalOpen}
              className="fa fa-times"
              aria-hidden="true"
            ></i>
          </div>
        </Modal.Header>
        <Modal.Content>
          <div className="d-flex" style={{ gap: '24px' }}>
            <div className="clarity-wrapper">
              {def_names &&
                Object?.entries(def_names)?.map(([key, value], index) => (
                  <div
                    className={`clarity-box ${active === key ? 'active' : ''}`}
                    onClick={() => setActive(key)}
                  >
                    {/* <div className="clarity-checkbox">
                     
                    </div> */}
                    <div
                      className="clarity-text"
                      onClick={() => setActive(key)}
                    >
                      <div className="d-flex justify-content-between w-100">
                        <h5
                          className="font-weight-bold"
                          style={{ fontSize: '16px' }}
                        >
                          {value?.icon} {value?.mainTitle}{' '}
                        </h5>
                        {/* {!getValue(key) && (
                          <div>
                            <p>Mark as done</p>

                            <Checkbox
                              checked={getValue(key)}
                            toggle
                            />
                          </div>
                        )} */}
                        <Checkbox checked={getValue(key)} color="green" />
                      </div>
                      {/* <div
                        className="d-flex justify-content-between"
                        style={{ width: '100%' }}
                      >
                        <p>{value?.title} </p>
                      </div> */}
                      <div>
                        {/* <Button variant='contained' className="mt-4" style={{height:'25px'}} onClick={()=>value?.open ? window?.open(value?.open) :navigate.push(value?.linkTo)} > <span><ArrowOutwardIcon/> <span>{value?.subTitle}</span></span></Button>
                   {value?.button2 &&<Button variant='contained' className="mt-4 ml-3" style={{height:'25px'}} onClick={()=>value?.open ? window?.open(value?.open2) :navigate.push(value?.linkTo2)} > <span><ArrowOutwardIcon/> <span>{value?.button2}</span></span></Button>} */}
                        {/* ()=>key==='personalize_widget' ? addFlag() :null */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="video-wrapper">
              <div className="number-clarity-wrapper" style={{ marginTop: 0 }}>
                {/* <p style={{ fontSize: '20px' }}>
                  L
                </p> */}
                <span className="number-clarity">{`${Array.isArray(currentStage)
                  ? currentStage?.filter(obj => obj?.[1])?.length
                  : 0
                  }/${Object?.keys(def_names)?.length} Completed`}</span>
              </div>
              {/* <video width="320" height="240" autoplay>
  <source src={def_names[active]?.videoLink} type="video/mp4"/>

</video> */}
              <div className="video-title">
                {/* <p style={{ fontSize: '20px' }}>
                  L
                </p> */}
                <h3>{def_names[active]?.title}</h3>
                <p>{def_names[active]?.subTitle}</p>

                {def_names[active]?.btnName}
              </div>
              {/* <iframe
                className="iframe-video"
                title="video"
                frameborder="0"
                width="100%"
                src={def_names[active]?.videoLink}
                allowfullscreen
              ></iframe> */}
              <div className="instruction-wrapper" style={{
                background: '#e2eaff',
                padding: '15px'
              }}>

                <img src={stock} alt="stock" height={60} width={70} />
                <div

                >
                  <h5>Need help getting started</h5>
                  <p>
                    <Link onClick={() => window.open('https://limephone.io/onboarding')}> Get in touch</Link> with one of our friendly onboarding specialists and we'll help you get set up
                  </p>

                </div>
              </div>
              {/* <div>
                <h3>Download apps</h3>
                <div
                  style={{
                    border: '1px solid lightgray',
                    padding: '15px'
                  }}
                >
             
                  <div className='button-wrapper'>
                    <p> <SmartphoneIcon /> Mobile App</p>
                    <span
                      className='download-buttons'
                    >
                      <img src={googlePlayStore} alt="windows" height={25} width={25} />  Google Play Store

                    </span>
                    <span className='download-buttons'
                    >
                      <img src={appStore} alt="windows" height={25} width={25} />      Web Dialer
                    </span>
                  </div>
                  <div className='button-wrapper'>
                    <p> <ExtensionOutlinedIcon /> Chrome Extension</p>
                    <span
                      className='download-buttons'
                    >
                      Install Extension
                    </span>
                   
                  </div>
                </div>
              </div> */}

            </div>
          </div>{' '}
        </Modal.Content>
      </Modal>
      <div
        className={classNames('menu-link', {
          'nav-active': open
        })}
        onClick={() => handleModalOpen()}
      >
        <div className="icon-wrapper">
          <img
            src={tour}
            alt="icon"
          />
          <span>Tour</span>
        </div>
      </div>
      {openSms && (
        <SendMessageModal isModalClose={() => setOpenSms(!openSms)} open={openSms} />
      )}
    </>
  )
}


export default connect(null, {
  getDialer
})(Clarity)