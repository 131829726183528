import React, { useState, useEffect } from 'react'
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { useParams } from "react-router-dom";
import { connect } from "react-redux"
import { getWidgetSettings, getWidgetInstall, getCallForwadingSettings } from "../actions/settings"
import CommonSelect from '../common/CommonSelect3'
import CallForwarding from '../components/settings/CallForwarding2'
import axios from "axios"
import deleteIcon from '../assets/images/delete-icon.png'
import { Accordion, Icon } from 'semantic-ui-react'
import { CommonNotify } from '../common/CommonNotify';
import Toggle from '../common/CommonToggle';
import { getSubscription } from '../config/subscription';


const OptionsTemplate = [

  {
    text: 'Classic',
    value: 1
  },
  {
    text: 'Smart',
    value: 2
  },
  {
    text: 'Modern',
    value: 3
  },
  {
    text: 'Callback',
    value:6
  }
]

const OptionsGreet = [

  {
    text: 'Template 1',
    value: 1
  },
  {
    text: 'Template 2',
    value: 2
  }
]


const OptionsRoute = [

  {
    text: 'Simultaneously',
    value: '0'
  },
  {
    text: 'In a sequence',
    value: '1'
  },
  {
    text: 'In a random sequence',
    value: '2'
  }
]


const status = {
  callTitle: 'Status',
  callDesc:
    'When turned on, our system is permitted to make automated calls to your customers when requited',
  callId: 'active',
  callref: 'active'
}
const WidgetPreview = ({ props, getWidgetSettings, getWidgetInstall, widget, widgetInstall, callForwadingSettings, getCallForwadingSettings }) => {
  const params = useParams()
  const [apiKey, setApiKey] = useState(params)
  const [activeIndex, setActiveIndex] = useState(-1)
  const [algoSetting, setAlgoSetting] = useState("")
  const [statusData, setStatus] = useState(widgetInstall?.data?.active)
  const [widgetSetting, setWidgetSetting] = useState({...widget?.data})
  const [subscriptionData, setSubscriptionData] = useState({})
  useEffect(() => {
    if (!widget) {
      console.log("widget undefined")
      getWidgetSettings()
      getWidgetInstall()
      getCallForwadingSettings() 
    }
    getSubScri()
    do_load()
  }, [])

  const getSubScri=async()=>{
    const data=await getSubscription()
       setSubscriptionData({...data?.data?.data})
      }
  




  useEffect(() => {


    if (!callForwadingSettings?.data) return;

    setAlgoSetting(callForwadingSettings?.data?.call_settings?.call_process?.toString())

    console.log("call settings", callForwadingSettings.data.call_settings.call_process.toString())

  }, [callForwadingSettings])



  const AutoplaySlider = withAutoplay(AwesomeSlider);


  const updateAlgoSetting = async setting => {
    //   const apiToken = await localStorage.getItem('access_token')
    //     this.setState({isLoading: true})


    // const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/call-algorithm`
    //   axios
    //     .post(url, data)
    //     .then(res => {
    //       this.setState({isLoading: false})
    //       if (res.data.message[0] == 'Successfully') {
    //         CommonNotify('Call Algorithm Successfully Updated', 'success')         
    //       }
    //     })
    //     .catch(error => {
    //       this.setState({isLoading: false})
    //       CommonNotify('Cant update call algorithm ')
    //     })
  }


  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  const slider = (
    <AutoplaySlider
      play={true}
      buttons={false}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={3000}
      bullets={false}
    >
      <div data-src="https://limecall.com/wp-content/uploads/2020/07/img-dynamic-hero-4.png" />
      <div data-src="https://limecall.com/wp-content/uploads/2020/07/img-dynamic-hero-1.png" />
      <div data-src="https://limecall.com/wp-content/uploads/2020/07/img-dynamic-hero-5.png" />
      <div data-src="https://limecall.com/wp-content/uploads/2020/07/img-dynamic-hero-2.png" />
    </AutoplaySlider>
  );



  const do_load = () => {

    let path = window.location.pathname
    let apiKey = path.split("widget-preview/")[1]
    console.log("recieved key  " + apiKey)

    var my_script = new_script('https://dev-widget.limecall.com/widget.js', apiKey);

    if (process.env.REACT_APP_BASE_APP_URL !== "https://qa.limecall.com") {
      new_script('https://widget.limecall.com/widget.js', apiKey);
    }

    my_script.then(function () {

    }).catch(function () {

    })
  }

  const new_script = (src, apiKey) => {
    return new Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.src = src;
      script.setAttribute("data-api-url", `${process.env.REACT_APP_BASE_APP_URL}`)
      script.setAttribute("data-api-key", apiKey)
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    })
  };


  const postSetting = data => {

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/set-customizations`
    return axios.post(url, data)

  }
  const handleStatusData = async toggleData => {

   
   
    let WSetting  =  {...widgetInstall?.data} 
    let Wsetting2 = {...widgetInstall?.data} 

    WSetting.active = WSetting.active ? 0 : 1
    Wsetting2.active = !Wsetting2.active
    
   

    setWidgetSetting(Wsetting2) 
   


     const apiToken = await localStorage.getItem('access_token')
    // loading(true)
    
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/general-settings`

    
      

    axios
      .post(url, WSetting)
      .then(res => {

        setStatus(Wsetting2.active)
        // loading(false)        
        if (res.data.message == 'Successfully') {                   
          CommonNotify('Data successfully updated...', 'success')         
        } else {
          CommonNotify('Data cannot saved System error occurred...')
        }
      })
      .catch(error => {
        if (error.response) {
        } else if (error.request) {

         
         
        } else {
        
         
        }
      })

  }
  const onChangeTemplate = val => {
    changeWidget('template_type', val.value.toString())
  }

  const onChangeBubble = val => {
    changeWidget('bubble_template', val.value)
  }

  const changeWidget = (index, value) => {
    let widgetSettingNew = { ...widgetInstall.data }



    widgetSettingNew[index] = value


    postSetting(widgetSettingNew)
      .then(res => {

        getWidgetSettings()
        getWidgetInstall()
        do_load()
        // console.log("done")
      })
      .catch(error => {
        console.log("error")
      })


  }



  const css = `
    body {
      background-color: #5135f7;
    }
    .row{
      margin-right: unset !important;
      margin-left: unset !important;
    }
    .intercom-launcher{
      display:none !important;
    }
`

  return (

    <div className="widget-preview">
      <style>{css}</style>

      <span className="demo">
        Widget Preview
      </span>
      <main className='row col-md-12'>
        <div className='row col-md-8'>
          <div className='col-md-2'>

          </div>
          <div className="col-md-5" style={{ marginTop: "30px", paddingRight: "0px" }}>
            {slider}
          </div>

          <article className="col-md-5" style={{ paddingLeft: "15px", paddingRight: "10px" }}>


            <img className='logo' src="https://limecall.com/wp-content/uploads/2019/12/logo_blue.png" style={{ width: "80%", marginBottom: "50px" }} alt="widget preview" />
            <p className="description">
              Try launching the widget, triggering a call, scheduling a meeting or sending a message. This simulates how leads and calls would be initiated from customers, users or teammates visiting your page.
            </p>
          </article>
        </div>
        <div className='col-md-4' >
          <div className="widgetconfigpreview-tab">
            <div className='widgetconfigpreview-title'>
              Customize your widget
            </div>
            <div className='row'>
              <div className='col-md-6'>
                Widget template
              </div>
              <div className='col-md-6'>

                <CommonSelect
                  name="select-template"
                  className="popup-font-select"
                  placeholder="Select Template"
                  options={OptionsTemplate}
                  value={widget?.data?.template_type}
                  onChange={(e, result) => onChangeTemplate(result)}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
              Call Button template
              </div>
              <div className='col-md-6'>

                <CommonSelect
                  name="select-template"
                  className="popup-font-select"
                  placeholder="Select greetings"
                  options={OptionsGreet}
                  value={widget?.data?.bubble_template}
                  onChange={(e, result) => onChangeBubble(result)}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                Call routing
              </div>
              <div className='col-md-6'>

                <CommonSelect
                  name="select-template"
                  className="popup-font-select"
                  placeholder="Select routing"
                  options={OptionsRoute}
                  value={algoSetting}
                  onChange={(e, result) => updateAlgoSetting(result)}
                />

              </div>
            </div>



            <div className='row'>
              <div className='col-md-6'>
                Agents online
              </div>
              <div className='col-md-6'>
                {/* {this.state.home} */}
                {widget?.data?.agents_online?.length ? (
                  <ul style={{ listStyle: "none" }}>
                    {widget?.data?.agents_online?.map(member => (
                      <li> <img style={{ width: 40, height: 40, display: "block", margin: "2px auto", borderRadius: 20 }} src={member?.profile_image} />  {member?.name}  </li>
                    ))}
                  </ul>
                ) : (<p>No agent online to receive calls</p>)}
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6'>
                Online Status
              </div>
              <div className='col-md-6'>
                {/* {this.state.home} */}
                {widget?.data?.plan_status ? 'Active' : 'Inactive'}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                Enable Widget
              </div>
              <div className="groupbtn-wrapper">
          <Toggle
            handleToggleData={(data) => handleStatusData(data)}          
            dataStateToggle={status} 
            activeDefault={statusData || widget?.data?.active}
            setText={{on:"Enabled", off:"Disabled"}}
          />
        </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                Subscription Status
              </div>
              <div className='col-md-6'>
                {/* {this.state.home} */}
                {subscriptionData?.status}
              </div>
            </div>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
              >
                <Icon name='dropdown' />
                Allowed Domains
              </Accordion.Title>

              <Accordion.Content active={activeIndex === 0}>
                <div className='row' style={{ marginTop: "16px" }}>

                  <div className='col-md-12'>

                    <table className="table">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Domain</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {widget?.data?.available_domains?.length ? (
                          <>

                            {widget?.data?.available_domains?.map((member, key) => (
                              <tr>

                                <td>{key + 1}</td>
                                <td> {member} </td>
                                <td> <img src={deleteIcon} alt="placeholder" /></td>

                              </tr>
                            ))}

                          </>

                        ) : (<p>Domain is empty</p>)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Accordion.Content>


              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}
              >
                <Icon name='dropdown' />
                Forward Numbers
              </Accordion.Title>

              <Accordion.Content active={activeIndex === 1}>
                <div className='row' style={{ marginTop: "16px" }}>

                  <div className='col-md-12' style={{ overflow: "auto" }}>
                    <CallForwarding />
                  </div>
                </div>
              </Accordion.Content>

            </Accordion>




          </div>
        </div>

      </main>
    </div>
  )
}

const mapStateToProps = state => ({
  widget: state.settings.widget,
  widgetInstall: state.settings.widgetInstall,
  callForwadingSettings: state.settings.callForwadingSettings,
});

export default connect(
  mapStateToProps,
  { getWidgetSettings, getWidgetInstall, getCallForwadingSettings }
)(WidgetPreview)