import React, { Component } from 'react'
import { Button, Dropdown, Accordion } from 'semantic-ui-react'
import ReactPhoneInput from 'react-phone-input-2'
import queryString, { parse } from 'query-string'
import Axios from 'axios'
import PhoneInput from 'react-phone-input-2'
import { Table } from 'semantic-ui-react'
import AddIcon from '@mui/icons-material/Add'
import Switch from '@mui/material/Switch'
import CommonInput from '../../common/CommonInput'
import CommonSelect from '../../common/CommonSelect'
import NodeToggle from '../../common/NodeToggle2'
import { styled } from '@mui/material/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import { CommonNotify } from '../../common/CommonNotify'
import { da, th } from 'date-fns/locale'
import { assign } from 'lodash-es'
import { Textarea } from 'evergreen-ui'
import WorkingHours from '../settings/WorkingHoursNumber'
import CommonButtons from '../../common/CommonButtons'
import { FormControlLabel, Menu, MenuItem , Dialog ,DialogTitle,IconButton, Typography} from '@mui/material'
import { Badge, Col, Row, Modal } from 'react-bootstrap'
import userImg from '../../assets/images/user.png'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined'
import MicOutlinedIcon from '@mui/icons-material/MicOutlined'
// import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import VoicemailOutlinedIcon from '@mui/icons-material/VoicemailOutlined'
import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import { check } from 'prettier'
import { data } from 'browserslist'
import CountryData from '../../../src/helpers/data/countryCode.json'
import saveIcon from '../../assets/images/save.png'
import { connect } from 'react-redux'
import { getWidgetCountries } from '../../actions/settings'



const keyList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
const AssignTo = [
  {
    key: 'Select',
    text: 'Select',
    value: ''
  },
  {
    key: 'Platform',
    text: 'Platform',
    value: 'platform'
  },
  {
    key: 'Team',
    text: 'Team',
    value: 'team'
  },
  {
    key: 'User',
    text: 'User',
    value: 'user'
  },
  {
    key: 'Voicemail',
    text: 'Voicemail',
    value: 'voicemail'
  },
  {
    key: 'PhoneMenu',
    text: 'IVR',
    value: 'phoneMenu'
  }
  // {
  //   key: 'PhoneMenu',
  //   text: 'Phone Menu',
  //   value: 'phoneMenu'
  // }
]

const grettingOptions = [
  {
    key: 'select_template',
    text: 'Select Template',
    value: 'Select Template'
  },
  {
    key: 'custom_template',
    text: 'Custom Template',
    value: 'Custom Template'
  }
]
const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#7438ff',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#7438ff',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))
class Configure extends Component {
  constructor(props) {
    super(props)
    this.audio = React.createRef()
    this.state = {
      speechToTextStatus: {},
      voicemailDelay: 0,
      holdMusic: null,
      holdBlob: null,
      recordState: null,
      anchorEl: null,
      addUserFlag: false,
      defaultAssignTo: '',
      phoneNumber: null,
      beforeVoiceMail: '',
      allMembars: [],
      defaultAssigned: null,
      showAssignValues: false,
      assignedData: null,
      beforeVoiceFile: null,
      showEditButton: false,
      nameFromAPI: '',
      callToggle: null,
      sharedIds: [],
      shareToggle: null,
      autoReplyAfter: null,
      autoReplyDuring: null,
      afterCallMessage: null,
      afterCallMessageText: '',
      afterCallMessageUpdated: false,
      userNotes: '',
      userNotesUpdated: false,
      missedCallMessage: null,
      activeIndex: false,
      missedCallMessageText: '',
      missedCallMessageUpdated: false,
      afterSmsMessage: null,
      afterSmsMessageText: '',
      afterSmsMessageUpdated: false,
      numberStatus: null,
      shareStatus: false,
      numberName: '',
      greeting: false,
      recording: true,
      call_start_greeting: null,
      whisper: false,
      callerId: false,
      numberId: null,
      selectedMembers: null,
      configuration: null,
      selectedFileName: null,
      file: null,
      webhookURL: null,
      assignToData: {
        placeholderValue: 'Select Platform',
        label: 'platform',
        platform: {
          label: 'platform',
          options: []
        },
        team: {
          label: 'team',
          options: []
        },
        user: {
          label: 'user',
          options: []
        },
        IVRUserData: {
          label: 'user',
          options: []
        },
        voicemail: {
          label: 'user',
          options: []
        }
      },
      voiceModal: false,
      grettingDropdown: null,
      voiceModalType: null,
      blob: null,
      phoneMenuStatus: false,
      greetingMessageStatus: false,
      deleteNumberFlag: false,
      defaultPlayBack: [],
      menuOptions: [{ key: '0', destination: 'team' }],
      selectedKeys: ['0'],
      ivrDetails: null,
      membersRoleList:[],
      voiceMailStatus:this.state?.number?.voicemail_status

    }
  }

  handleGetPlayBackDefault = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/playbacks-templates?type=App\UserPhoneNumber`
    await Axios.get(url)
      .then(async res => {
        if (res.data) {
          this.props.loading(false)
          const data = await res.data.data
          this.setState({
            ...this.state,
            defaultPlayBack: res.data.data || []
          })
        }
      })
      .catch(err => {
        this.props.loading(false)
      })
  }

  handleUpdatePhoneStatus = () => {
    this.setState({
      phoneMenuStatus: !this.state.phoneMenuStatus
    })
    const data = {
      ivr_status: !this.state.phoneMenuStatus === true ? 1 : 0
    }
    this.UpdatePhoneState(data)
  }

  start = () => {
    this.setState({
      recordState: RecordState?.START
    })
  }

  stop = () => {
    this.setState({
      recordState: RecordState?.STOP
    })
  }

  onStop = audioData => {
    let audioData1 = audioData
    const date = new Date()
    audioData1.blob.lastModified = date?.getTime()
    audioData1.blob.lastModifiedDate = date
    audioData1.blob.name = 'voicemail.wav'
    this.setState({
      beforeVoiceFile: audioData1?.blob,
      voiceMailUpdated: true,
      // blob: audioData?.url
      [this.state.voiceRecordType === 'greeting'
        ? 'greetingBlob'
        : 'blob']: audioData?.url
    })
  }

  handleClick = (event, data) => {
    this.setState({
      selectedData: data,
      anchorEl: event?.currentTarget
    })
  }
  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }
  handleDelete = data => {
    this.onDeleteShareids(data)
  }

  handleVocieMailClick = (event, type) => {
    this.setState({
      ...this.state,
      grettingDropdown: event.currentTarget,
      voiceRecordType: type
    })
  }

  handleVoicemailClose = value => {
    if (value === 'voicemailGreeting') {
      this.saveDefaultVoiceMail(false)
      // this.handleGetPlayBackDefault()
    }
    if (value === 'greeting') {
      // this.saveDefaultVoiceMail(false)
      this.handleGetPlayBackDefault()
    }
    this.setState({
      grettingDropdown: null
    })
    if (value) {
      if (value !== 'voicemailGreeting') {
        this.setState({
          voiceModalType: value
        })
        this.handleGreetingModal()
      }
    }
  }

  getNumberAPI = async parsed => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/numbers/${parsed}`

    await Axios.get(url)
      .then(async res => {
        if (res.data) {
          this.props.loading(false)
          const data = await res.data.data
          this.setSettings(data)
        }
      })
      .catch(err => {
        //this.props.history.push('/numbers')
        // window.location.replace('/numbers')
        this.props.loading(false)
      })
  }

  getScript = async assignedName => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    const { assignToData } = this.state

    await Axios.get(URL)
      .then(res => {
        if (res.data.data) {
          this.props.loading(false)
          const data = []
          localStorage.setItem('widget_active', res.data.data[0].active)
          res.data.data.map(platform => {
            data.push({
              key: platform.name,
              text: platform.name,
              value: platform.id
            })
          })
          assignToData.platform.options = data
          this.setState(
            {
              ...this.state,
              assignToData
            },
            () => {
              //this.setState({ selectedMembers : assignedName})
            }
          )
        }
      })
      .catch(err => {})
  }

  setSettings = async data => {
    let assigned = data.assigned
    const assignToData = { ...this.state.assignToData }
    if (!assigned) {
      assigned = {}
    }

    if (!assigned?.type) {
      assigned.type = ''
    }

    // return;

    let assignedName = ''
    const assignedType = assigned?.type
      ? assigned.type == 'widget'
        ? 'platform'
        : assigned.type
      : ''
    assignedName = assigned?.id
    assignToData.label = assignedType
    var selectedData
    var nameArr
    if (assigned !== null) {
      selectedData = assigned.id
    } else {
      selectedData = null
    }

    const callToggle = [
      {
        callTitle: 'Call Recording',
        callDesc:
          'Activate this feature to get a recording of each call. The recordings will be saved in your call logs.',
        callId: 'toggleCallShowBtn',
        callref: 0,
        status:
          data.configuration === null
            ? false
            : data.configuration.record_calls === 0
            ? false
            : true
      }
      // {
      //   callTitle: 'Play a greeting to callers',
      //   callDesc: '',
      //   callId: 'toggleCallerShowBtn',
      //   callref: 1,
      //   status: data.configuration === null ? false :  (data.configuration.use_start_greeting === 0 ? false : true)
      // }
    ]

    const shareToggle = {
      callTitle: 'Share number',
      callDesc:
        'Users can see and use this inbox from the side menu, receive all notifications by default, and view all calls and messages.',
      callId: 'toggleSharePhoneShowBtn',
      callref: 0,
      status: data.access === null ? false : data.access === 2 ? true : false
    }

    const internationCall = {
      callTitle: 'International Calling and messaging',
      callDesc:
        'Allow international calling and messaging on this phone numner. Extra charges will apply per message send and call minute.',
      callId: 'toggleInternationalPhoneShowBtn',
      callref: 0,
      status: data.access === null ? false : data.access === 2 ? true : false
    }

    const numberStatus = {
      callTitle: 'Phone number status',
      callDesc: 'This feature enable or disable current number.',
      callId: 'toggleNumberPhoneShowBtn',
      callref: 0,
      status: data.status === null ? false : data.status === 1 ? true : false
    }

    const afterCallMessage = {
      callTitle: 'Auto replies after call',
      callDesc: 'Send an auto text message at the end of each call.',
      callId: 'toggleAfterCallShowBtn',
      callref: 0,
      status:
        data.after_call_message_status === null
          ? false
          : data.after_call_message_status === 1
          ? true
          : false
    }

    const missedCallMessage = {
      callTitle: 'Auto replies after missed call',
      callDesc: 'Automatically send a text message if you miss a call.',
      callId: 'toggleAfterMissedShowBtn',
      callref: 0,
      status:
        data.missed_call_message_status === null
          ? false
          : data.missed_call_message_status === 1
          ? true
          : false
    }

    const afterSmsMessage = {
      callTitle: 'Auto replies after text',
      callDesc: 'Send an auto text reply after each incoming text message.',
      callId: 'toggleAfterSmsShowBtn',
      callref: 0,
      status:
        data.after_sms_message_status === null
          ? false
          : data.after_sms_message_status === 1
          ? true
          : false
    }

    const voiceMail = {
      callTitle: 'Voicemail',
      callDesc: `Customize this phone number's voicemail`,
      callId: '',
      callref: 0,
      status:
        data.voicemail_status === null
          ? false
          : data.voicemail_status === 1
            ? true
            : false,
      NoToggle:true
    }

    const greetingData = {
      callTitle: '',
      callDesc: ``,
      callId: 'toggleGreetingStatus',
      callref: 0,
      status:
        data.configuration.use_start_greeting === null
          ? false
          : data.configuration.use_start_greeting === 1
          ? true
          : false
    }

    const callTranscriptData = {
      callTitle: '',
      callDesc: ``,
      callId: 'togglecallTranscriptShowBtn',
      callref: 0,
      status:
        data?.speech_to_text_status === null
          ? false
          : data?.speech_to_text_status === 1
          ? true
          : false
    }

    const autoReplyAvilable = {
      callTitle: 'Auto reply text available during call',
      callDesc: '',
      callId: 'toggleAutoReplyShowBtn',
      callref: 0,
      status:
        data.auto_reply_availability_status === null
          ? false
          : data.auto_reply_availability_status === 1
          ? true
          : false
    }

    const autoReplyNotAvilable = {
      callTitle: 'Auto reply text not available during call',
      callDesc: '',
      callId: 'toggleAutoReplyNotShowBtn',
      callref: 0,
      status:
        data.auto_reply_not_availability_status === null
          ? false
          : data.auto_reply_not_availability_status === 1
          ? true
          : false
    }

    const deleteNumber = {
      callTitle: 'Delete Phone Number',
      callDesc:
        'By deleting this phone number you will lose access to all messages and calls to and from',
      callId: 'toggleDeleteShowBtn'
    }
    // shared contacts

    const sharedWith = data.shared_with.map(item => item.id)

    //auto replay non bussinnes hours

    const nonBusinessAfterCall = {
      callTitle: 'Auto replies after non business hours calls',
      callDesc:
        'Send an auto text message for all missed calls during non business hours.',
      value: data?.non_business_incoming_call_auto_reply_text,
      callId: 'toggleAfterNonBussinessTextShowBtn',
      callref: 0,
      status:
        data.non_business_incoming_call_auto_reply_text_status === null
          ? false
          : data.non_business_incoming_call_auto_reply_text_status === 1
          ? true
          : false
    }

    const nonBusinessAfterText = {
      callTitle: 'Auto replies after non business hours text',
      callDesc:
        'Send an auto text message for all incoming SMS during non business hours.',
      value: data?.non_business_receive_msg_auto_reply_text,
      callId: 'toggleAfterNonBussinessCallShowBtn',
      callref: 0,
      status:
        data.non_business_receive_msg_auto_reply_text_status === null
          ? false
          : data.non_business_receive_msg_auto_reply_text_status === 1
          ? true
          : false
    }

    const nonBusinessAfterVoice = {
      callTitle: 'Auto replies after non business hours voicemail',
      callDesc:
        'Send an auto text message after all voice mails received during non business hours.',
      value: data?.non_business_voicemail_auto_reply_text,
      callId: 'toggleAfterNonBussinessvoiceShowBtn',
      callref: 0,
      status:
        data.non_business_voicemail_auto_reply_text_status === null
          ? false
          : data.non_business_voicemail_auto_reply_text_status === 1
          ? true
          : false
    }

    await this.setState(
      {
        ...this.state,
        speechToTextStatus: callTranscriptData,
        dataLoad: true,
        voicemailDelay: data?.duration_before_voicemail,
        holdMusic: data?.hold_music_url,
        nonBusinessAfterText: nonBusinessAfterText,
        nonBusinessAfterCall: nonBusinessAfterCall,
        nonBusinessAfterVoice: nonBusinessAfterVoice,
        assignToData: assignToData,
        defaultAssignTo: assignedType === 'ivr' ? 'phoneMenu' : assignedType,
        showAssignValues: assigned === null ? false : true,
        beforeVoiceMail: data.before_voicemail,
        greetingVoiceMail: data?.configuration?.call_start_greeting,
        assignedData: assigned,
        callToggle: callToggle,
        userNotes: data?.note,
        userNotesUpdated: false,
        numberName: data.friendly_name,
        nameFromAPI: data.friendly_name,
        phoneNumber: data.phone_number,
        internationalCall: internationCall,
        shareToggle: shareToggle,
        deleteNumber: deleteNumber,
        shareStatus: shareToggle.status,
        afterCallMessage: afterCallMessage,
        afterCallMessageText: data.after_call_message,
        missedCallMessage: missedCallMessage,
        missedCallMessageText: data.missed_call_message,
        afterSmsMessage: afterSmsMessage,
        voiceMail: voiceMail,
        voiceMailUpdated: false,
        greetingData: greetingData,
        greetingUpdate: false,
        afterSmsMessageText: data.after_sms_message,
        numberStatus: numberStatus,
        sharedIds: sharedWith,
        recording: false,
        assignToId: data.id,
        outgoing_webhook: data?.outgoing_webhook || {},
        webhookURL: data?.outgoing_webhook?.url || '',
        owner:data?.owner,
        voiceMailStatus:data?.voicemail_status
      },
      async () => {
        await this.getTeams(assignedName)
        await this.getUsers(assignedName)
        await this.getScript(assignedName)
      }
    )
  }

  componentDidMount = async () => {
    this.props.loading(true)
    const parsed = queryString.parse(window.location.search)
    this.handleGetNumberIVR()
    this.getMembersRole()
    await this.getQueryString()
    this.handleGetShareNumber()
    if (this.props?.widgetCountries?.data?.allowed_countries) {
    } else {
      return this.props?.getWidgetCountries()
    }
  }

  getMembersRole = async() => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/member-roles`
    await Axios.get(url)
     .then(async res => {
      if (res.data) {
        this.props.loading(false)
        const data = await res.data.data
        let ls=[]
        if(data?.length){
          data.forEach(item=>{
            if (item.name === 'Team Leader' || item.name === 'Member') {
              ls.push({
                text:'Member',
                value:item.id
              })
            }
            if (item.name === 'Manager' || item.name === 'Admin') {
              ls.push({
                text:'Admin',
                value:item.id
              })
            }
            if (item.name === 'Client' || item.name === 'Owner') {
              ls.push({
                text:'Owner',
                value:item.id
              })
            }
          })
        }

        this.setState({
          ...this.state,
          membersRoleList : ls,
        })
      }
    })
    .catch(err => {
      this.props.loading(false)
    })
  }

  resetWidgetCountries = countries => {
    const allCountry = CountryData
    const allowed = allCountry
      ?.filter(item => {
        if (countries?.includes(item?.name)) {
          return item
        }
      })
      .map(item => item?.code?.toLowerCase())
    return allowed
  }
  voicemailHandler = async (value) => {
    
    // write API code here
    const parsed = this.props?.paramId
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${parsed}/voicemail-status`
    const data = { voicemail_status: value}
    await Axios.put(url, data)
      .then(async res => {
        this.setState({
          ...this.state,
          voiceMailStatus:value
        })
        CommonNotify('Updated successfully.')
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors[0] || 'Something went to wronng!'
        )
      })
  }
  renameNumber = async () => {
    const { numberId, numberName } = this.state

    if (numberName === '' || numberName === null) {
      CommonNotify('Please enter name first.')
    } else {
      this.props.loading(true)
      const idd = parseInt(numberId)
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${idd}/name`

      const data = {
        name: numberName
      }
      Axios.post(url, data)
        .then(res => {
          this.props.loading(false)
          this.setState({ nameFromAPI: numberName, showEditButton: false })
        })
        .catch(err => {
          this.props.loading(false)
        })
    }
  }
  getUsers = async assignedName => {
    this.props.loading(true)
    const { assignToData, allMembars } = this.state

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/members`

    await Axios.get(url)
      .then(res => {
        const { admin, members = [] } = res.data.data

        const user = []
        const data2 = []
        const shareMember = []
        this.props.loading(false)
        const IVRUser = []

        if (admin) {
          const dat1 = this.state?.shareAccessList?.filter((obj) => obj?.user_id === admin.id)
          if (!dat1 || dat1?.length === 0) {
            shareMember.push({
              key: admin.first_name,
              value: admin.id,
              text: admin.first_name + ' ' + admin.last_name
            })
          }


          IVRUser.push({
            key: admin.first_name,
            value: admin.id,
            text: admin.first_name + ' ' + admin.last_name
          })

          user.push({
            key: admin.first_name,
            value: admin.id,
            text: admin.first_name + ' ' + admin.last_name
          })
          data2.push({
            key: admin.first_name,
            value: admin.id,
            text: admin.first_name + ' ' + admin.last_name
          })
        }
        members.map(member => {


          if (member?.verified === 1) {
            IVRUser.push({
              key: member.first_name,
              value: member.id,
              text: member.first_name + ' ' + member.last_name
            })
          }
          const data = this.state?.shareAccessList?.filter((obj) => obj?.user_id === member.id)
          if (!data || data?.length === 0) {

            if (member?.verified === 1) {
              user.push({
                key: member.first_name,
                value: member.id,
                text: member.first_name + ' ' + member.last_name
              })
              shareMember.push({
                key: member.first_name,
                value: member.id,
                text: member.first_name + ' ' + member.last_name
              })

              data2.push({
                key: member.first_name,
                value: member.id,
                text: member.first_name + ' ' + member.last_name
              })
            }
          }
        })

        assignToData.user.options = user
        assignToData.IVRUserData.options = IVRUser

        this.setState(
          {
            ...this.state,
            assignToData,
            allMembars: data2,
            AllShareMember: shareMember,
          },
          () => {
            this.setState({ selectedMembers: assignedName })
          }
        )
      })
      .catch(err => {
        this.props.loading(false)
      })
  }


  getQueryString = async () => {
    const parsed = this.props.paramId

    if (!parsed) {
      //this.props.history.push('/numbers')
      // window.location.replace('/numbers')
    }

    await this.setState(
      {
        ...this.state,
        numberId: parsed
      },
      () => {
        this.getNumberAPI(parsed)
      }
    )
  }

  getTeams = async assignedName => {
    this.props.loading(true)
    const { assignToData } = this.state
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/team/teams?limit=20000&offset=0`
    Axios.get(url)
      .then(res => {
        if (res.data.data) {
          this.props.loading(false)
          const data = []
          res.data.data.map(team => {
            data.push({
              key: team.name,
              text: team.name,
              value: team.id
            })
          })
          assignToData.team.options = data
          this.setState(
            {
              ...this.state,
              assignToData
            },
            () => {
              //this.setState({ selectedMembers : assignedName})
            }
          )
        }
      })
      .catch(err => {
        this.props.loading(false)
      })
  }

  getNumberData = async () => {
    this.props.loading(true)
    const { numberId } = this.state
    const { assignToData } = this.state
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/numbers/${numberId}`

    Axios.get(url)
      .then(res => {
        if (res.data) {
          this.props.loading(false)
          const data = res.data.data
          this.setState({
            ...this.state,
            numberName: data.friendly_name
          })
        }
      })
      .catch(err => {
        this.props.loading(false)
      })
  }

  onChangeCountry = value => {
    this.setState({ numberAppNumber: value })
  }

  handleDataRef = (DataState, DataRef) => {
    if (DataRef === 0) {
      this.onCallRecording(DataState)
    } else {
      this.onGrettingCaller(DataState)
    }
  }

  onShareidsHandler = (e, data) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/share`
    const payload = {
      role: 2,
      user_id: data?.value[0]
    }
    Axios.post(url, payload)
      .then(res => {
        this.handleGetShareNumber()
        CommonNotify('Share number added successfully', 'success')
      })
      .catch(err => {})
  }

  onDeleteShareids = data => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/share/${data?.id}`
    Axios.delete(url)
      .then(res => {
        if (res) {
          CommonNotify('Share number deleted successfully', 'success')
          this.handleGetShareNumber()
        }
      })
      .catch(err => {})
  }

  handleUpdateShareRole = role => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/share/${role?.id}/role`
    const payload = {
      role: role?.role === 'Full Access' ? 1 : 2
    }
    Axios.put(url, payload)
      .then(res => {
        if (res) {
          CommonNotify('Role updated successfully', 'success')
        }
      })
      .catch(err => {})
  }

  handleShare = (DataState, DataRef) => {
    this.setState({ shareStatus: DataState })
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/share`
    const data = {
      access: DataState ? 2 : 1,
      shared_with: DataState ? this.state.sharedIds : []
    }
    Axios.post(url, data)
      .then(res => {
        // CommonNotify(
        //   DataState ? 'Record enabled successfully' : 'Record disabled successfully',
        //   'success'
        // )
      })
      .catch(err => {})
  }

  handleStatus = (DataState, DataRef) => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/status`

    const data = {
      status: DataState ? 1 : 0
    }
    Axios.put(url, data)
      .then(res => {
        // CommonNotify(
        //   DataState ? 'Record enabled successfully' : 'Record disabled successfully',
        //   'success'
        // )
      })
      .catch(err => {})
  }

  handleAfterCallMsg = (DataState, DataRef) => {
    let { afterCallMessage } = this.state
    afterCallMessage.status = DataState

    this.setState(
      { ...this.state, afterCallMessage, afterCallMessageUpdated: false },
      () => {
        this.handleAfterCallData()
      }
    )
  }

  handleAfterSmsMsg = (DataState, DataRef) => {
    let { afterSmsMessage } = this.state
    afterSmsMessage.status = DataState
    this.setState(
      { ...this.state, afterSmsMessage, afterSmsMessageUpdated: false },
      () => {
        this.handleAfterCallData()
      }
    )
  }

  handleVoiceMail = (DataState, DataRef) => {
    let { voiceMail, voiceMailUpdated } = this.state
    voiceMail.status = DataState
    this.setState({ ...this.state, voiceMail, voiceMailUpdated: false }, () => {
      this.updateVoiceMail()
    })
  }

  handleUpdateGreetingMessageStatus = (DataState, DataRef) => {
    let { greetingData, greetingUpdate } = this.state
    greetingData.status = DataState
    this.setState(
      { ...this.state, greetingData : greetingData, greetingUpdate: false },
      () => {
        this.greetingUpdateStatus(DataState)
      }
    )
  }

  handleUpdateCallTranscriptionStatus = (DataState, DataRef) => {
    let { speechToTextStatus } = this.state
    speechToTextStatus.status = DataState
    this.setState({ ...this.state, speechToTextStatus }, () => {
      this.callSpechUpdateStatus()
    })
  }

  callSpechUpdateStatus = () => {
    const { numberId, speechToTextStatus } = this.state
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${numberId}/call-speech-status`
    const data = {
      status: speechToTextStatus?.status ? 1 : 0
    }
    Axios.put(url, data)
      .then(res => {
        CommonNotify('Status updated successfully', 'success')
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors[0] || 'Something went to wronng!'
        )
      })
    // }
  }

  greetingUpdateStatus = (DataState) => {
    const { numberId, greetingData } = this.state
    // const stateValue = callToggle === null ? e : callToggle[1].status
    // if (e !== stateValue) {
    //   const tg = [...this.state.callToggle]

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/change-call-greeting-status`

    const data = {
      number_id: parseInt(numberId),
      use_start_greeting:DataState ? 1 : 0
    }
    Axios.post(url, data)
      .then(res => {
        // CommonNotify(
        //   e
        //     ? 'Call start greetings enabled successfully'
        //     : 'Call start greetings disabled successfully',
        //   'success'
        // )
        // tg[1].status = e
        //this.setState({callToggle : tg});
      })
      .catch(err => {})
    // }
  }
  /* handleGreetingMessage = (DataState, DataRef) => {
    let { greetingMessage, voiceMailUpdated } = this.state
    greetingMessage.status = DataState
    this.setState(
      { ...this.state, greetingMessage, voiceMailUpdated: false },
      () => {
        this.updateVoiceMail()
      }
    )
  } */

  updateVoiceMail = () => {
    let { voiceMail } = this.state

    const data = {
      voicemail_status: voiceMail.status ? 1 : 0
    }

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/voicemail-status`

    Axios.put(url, data)
      .then(res => {})
      .catch(err => {})
  }

  handleAfterMissedCallMsg = (DataState, DataRef) => {
    let { missedCallMessage } = this.state
    missedCallMessage.status = DataState

    this.setState(
      { ...this.state, missedCallMessage, missedCallMessageUpdated: false },
      () => {
        this.handleAfterCallData()
      }
    )
  }

  handleAutoReplyA = (DataState, DataRef) => {
    let { autoReplyAvilable } = this.state
    autoReplyAvilable.status = DataState

    this.setState(
      { ...this.state, autoReplyAvilable, autoReplyAvilableUpdated: false },
      () => {
        this.handleDuringCallData()
      }
    )
  }

  handleAutoReplyNA = (DataState, DataRef) => {
    let { autoReplyNotAvilable } = this.state
    autoReplyNotAvilable.status = DataState

    this.setState(
      { ...this.state, autoReplyNotAvilable, autoReplyAvilableUpdated: false },
      () => {
        this.handleDuringCallData()
      }
    )
  }

  handleNonBusinessText = (DataState, DataRef) => {
    let { nonBusinessAfterText } = this.state
    nonBusinessAfterText.status = DataState

    this.setState(
      {
        ...this.state,
        nonBusinessAfterText,
        nonBusinessAfterTextUpdated: false
      },
      () => {
        this.handleAfterCallData()
      }
    )
  }

  handleNonBusinessCall = (DataState, DataRef) => {
    let { nonBusinessAfterCall } = this.state
    nonBusinessAfterCall.status = DataState

    this.setState(
      {
        ...this.state,
        nonBusinessAfterCall,
        nonBusinessAfterCallUpdated: false
      },
      () => {
        this.handleAfterCallData()
      }
    )
  }

  handleNonBusinessVoice = (DataState, DataRef) => {
    let { nonBusinessAfterVoice } = this.state
    nonBusinessAfterVoice.status = DataState

    this.setState(
      {
        ...this.state,
        nonBusinessAfterVoice,
        nonBusinessAfterVoiceUpdated: false
      },
      () => {
        this.handleAfterCallData()
      }
    )
  }

  handleAfterCallData = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/auto-reply-messages`
    const data = {
      after_call_message_status: this.state.afterCallMessage.status ? 1 : 0,
      missed_call_message_status: this.state.missedCallMessage.status ? 1 : 0,
      after_sms_message_status: this.state.afterSmsMessage.status ? 1 : 0,
      after_call_message: this.state.afterCallMessageText,
      missed_call_message: this.state.missedCallMessageText,
      after_sms_message: this.state.afterSmsMessageText,
      non_business_receive_msg_auto_reply_text: this.state.nonBusinessAfterText
        ?.value,
      non_business_receive_msg_auto_reply_text_status: this.state
        .nonBusinessAfterText?.status
        ? 1
        : 0,
      non_business_incoming_call_auto_reply_text: this.state
        .nonBusinessAfterCall?.value,
      non_business_incoming_call_auto_reply_text_status: this.state
        .nonBusinessAfterCall?.status
        ? 1
        : 0,
      non_business_voicemail_auto_reply_text: this.state.nonBusinessAfterVoice
        ?.value,
      non_business_voicemail_auto_reply_text_status: this.state
        .nonBusinessAfterVoice?.status
        ? 1
        : 0
    }
    Axios.post(url, data)
      .then(res => {
        // CommonNotify('Updated successfully.')
        this.setState({
          nonBusinessAfterVoiceUpdated: false,
          nonBusinessAfterCallUpdated: false,
          nonBusinessAfterTextUpdated: false,
          afterSmsMessageUpdated: false,
          missedCallMessageUpdated: false,
          afterCallMessageUpdated: false
        })
      })
      .catch(err => {})
  }

  handleDuringCallData = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/auto-reply-availability-text`
    const data = {
      auto_reply_availability_status: this.state.autoReplyAvilable.status
        ? 1
        : 0,
      auto_reply_not_availability_status: this.state.autoReplyNotAvilable.status
        ? 1
        : 0,
      auto_reply_availability_text: this.state.autoReplyAvilableText,
      auto_reply_not_availability_text: this.state.autoReplyNotAvilableText
    }

    if (this.state.autoReplyAvilable.status) {
      if (!this.state.autoReplyAvilableText) {
        return
      }
    }

    if (this.state.autoReplyNotAvilable.status) {
      if (!this.state.autoReplyNotAvilableText) {
        return
      }
    }

    Axios.post(url, data)
      .then(res => {
        // CommonNotify(
        //   DataState ? 'Record enabled successfully' : 'Record disabled successfully',
        //   'success'
        // )
      })
      .catch(err => {})
  }

  onCallRecording = async e => {
    const { numberId, callToggle } = this.state
    const stateValue = callToggle === null ? e : callToggle[0].status
    if (e !== stateValue) {
      const tg = [...this.state.callToggle]
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/change-number-record-status`
      const data = {
        number_id: parseInt(numberId),
        record_calls: e ? 1 : 0
      }
      Axios.post(url, data)
        .then(res => {
          CommonNotify(
            e ? 'Record enabled successfully' : 'Record disabled successfully',
            'success'
          )
          tg[0].status = e
          //this.setState({callToggle : tg});
        })
        .catch(err => {})
    }
  }

  onGrettingCaller = async e => {
    const { numberId, callToggle } = this.state
    const stateValue = callToggle === null ? e : callToggle[1].status
    if (e !== stateValue) {
      const tg = [...this.state.callToggle]

      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/change-call-greeting-status`

      const data = {
        number_id: parseInt(numberId),
        use_start_greeting: e ? 1 : 0
      }
      Axios.post(url, data)
        .then(res => {
          // CommonNotify(
          //   e
          //     ? 'Call start greetings enabled successfully'
          //     : 'Call start greetings disabled successfully',
          //   'success'
          // )
          tg[1].status = e
          //this.setState({callToggle : tg});
        })
        .catch(err => {})
    }
  }

  handleBeforeVoiceFile = event => {
    this.setState({
      beforeVoiceFile: event.target.files[0],
      voiceMailUpdated: true
    })
  }

  saveBeforeVoiceMail = () => {
    let { beforeVoiceFile, voiceMailUpdated, voiceRecordType } = this.state
    this.setState({ voiceRecordFlag: true })
    const data = new FormData()
    if (voiceRecordType === 'greeting') {
      data.append('phone_number_id', this.state.numberId)
      data.append('call_start_greeting', beforeVoiceFile)
    } else {
      data.append('before_voicemail', beforeVoiceFile)
    }
    const url =
      voiceRecordType === 'greeting'
        ? `${process.env.REACT_APP_BASE_APP_URL}/api/v1/update-number-configs`
        : `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/before-voicemail`

    Axios.post(url, data)
      .then(res => {
        this.handleGreetingModal()
        CommonNotify(`Voicemail updated successfully`, 'success')
        this.setState({
          voiceMailUpdated: false,
          voiceRecordFlag: false,
          blob: null
        })
        const parsed = this.props.paramId
        this.getNumberAPI(parsed)
      })
      .catch(err => {
        this.setState({ voiceRecordFlag: false })
      })
  }

  saveDefaultVoiceMail = (value, list) => {
    const data = new FormData()
    const { voiceRecordType } = this.state
    data.append('playback_id', list?.id || 1905)
    data.append('assign_to_type', `App\\UserPhoneNumber`)
    data.append('assign_to_id', this.state.assignToId)
    data.append('action_column', 'before_voicemail')
    const tag = voiceRecordType === 'greeting' ? 'greeting' : 'voicemail'

    const greetingUrl = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/playbacks-templates?type=AppWidget&tag=${tag}`
    Axios.get(greetingUrl)
      .then(res => {
        if (value) {
          this.handleGreetingModal()
        }
        CommonNotify(
          `${
            voiceRecordType === 'greeting' ? 'Greeting message' : 'Voicemail'
          } updated successfully`,
          'success'
        )
        if (voiceRecordType === 'greeting') {
          this.setState({
            greetingUpdate: false,
            greetingBlob: null,
            greetingVoiceMail: res?.data?.data?.[0]?.path
          })
        } else {
          this.setState({
            voiceMailUpdated: false,
            blob: null,
            beforeVoiceMail: res?.data?.data?.[0]?.path
          })
        }
      })
      .catch(err => {})

    this.setState({
      grettingDropdown: null
    })
  } 
  handleGetShareNumber = () => {
    if (this.props.paramId || this.state.numberId) {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this
        .props.paramId || this.state.numberId}/share-access`
      Axios.get(url)
        .then(res => {
          this.setState({
            shareAccessList: res?.data?.data || []
          })
        })
        .catch(err => {})
    }
  }

  handleGetShareNumber = () => {
    if (this.props.paramId || this.state.numberId) {
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this
        .props.paramId || this.state.numberId}/share-access`
      Axios.get(url)
        .then(res => {
          this.setState({
            shareAccessList: res?.data?.data || []
          })
        })
        .catch(err => {})
    }
  }

  onAssingToHandler = (e, data) => {
    const { assignToData, assignedData } = this.state
    assignToData.label = data.value
    assignToData.placeholderValue = 'Select ' + data.value
    this.setState({
      ...this.state,
      assignToData,
      showAssignValues: true,
      defaultAssignTo: data.value
    })
    if (data.value === 'phoneMenu') {
      const postData = {
        type: 'ivr'
      }
      this.assignTheNumber(postData)
    }
    if (data.value === 'voicemail') {
      const data = {
        voicemail_status: 3
      }
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/voicemail-status`
      Axios.put(url, data)
        .then(res => {})
        .catch(err => {})
    }
  }

  onAssingToHandlerPhoneMenu = (e, data) => {
    const { name, value } = data
    let options = this.state.menuOptions
    let record = options[name]
    record.value = value
    options[name] = record
    this.setState({
      ...this.state,
      selectedMembers: data.value,
      menuOptions: options
    })
    const payload = {}
    payload[`ivr_status`] = true
    payload[`digit_numbers`] = [+record?.key + 1]
    payload[`ivr_ids`] = [+record.value]
    if (record?.destination === 'team') {
      payload[`ivr_types`] = ['App\\Team']
    } else {
      payload[`ivr_types`] = ['App\\User']
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/ivr-option`
    Axios.post(url, payload)
      .then(res => {
        CommonNotify(`successfully added`, 'success')
        this.handleGetNumberIVR()
      })
      .catch(err => {
        if (err?.response?.data?.errors && err?.response?.data?.errors?.length)
          return err?.response?.data?.errors.map(error => {
            return CommonNotify(
              error || err?.response?.message || `Something went to wrong!`,
              'warning'
            )
          })
      })
  }

  handleAddOption = () => {
    const cloneSelectedKeys = [...this.state.selectedKeys]
    const cloneMenuOptions = [...this.state.menuOptions]
    if (cloneMenuOptions?.length > 9) return
    const optionsAry = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const validOption = optionsAry?.find(
      option => !cloneMenuOptions?.map?.(el => el.key)?.includes(option)
    )
    this.setState({
      ...this.state,
      menuOptions: [
        ...this.state.menuOptions,
        { key: validOption, destination: 'team' }
      ],
      selectedKeys: [...cloneSelectedKeys, validOption]
    })
  }

  handleDeleteIVROption = (record, updatedArray) => {
    if (record) {
      const id = +record?.ids
      let data = {}
      data[`ivr_status`] = true
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/ivr-option/${id}`
      Axios.delete(url, data)
        .then(res => {
          CommonNotify(`successfully deleted`, 'success')
          this.handleGetNumberIVR()
          this.setState({
            ...this.state,
            menuOptions: updatedArray
          })
        })
        .catch(err => {
          if (
            err?.response?.data?.errors &&
            err?.response?.data?.errors?.length
          )
            return err?.response?.data?.errors.map(error => {
              return CommonNotify(
                error || err?.response?.message || `Something went to wrong!`,
                'warning'
              )
            })
        })
    }
  }

  handleDeleteKey = async i => {
    const record = this.state?.menuOptions[i]
    const updatedArray = this.state.menuOptions.filter((v, ind) => ind !== i)
    if (record?.isDelete || record?.ids) {
      this.handleDeleteIVROption(record, updatedArray)
    } else {
      this.setState({
        ...this.state,
        menuOptions: updatedArray
      })
    }
  }

  handleChangeKey = i => e => {
    const cloneSelectedKeys = [...this.state.selectedKeys]
    this.setState({
      ...this.state,
      menuOptions: this.state.menuOptions.map((val, ind) => {
        if (ind === i) {
          cloneSelectedKeys[ind] = e.target.value
          return { ...val, key: e.target.value }
        }
        return val
      }),
      selectedKeys: cloneSelectedKeys
    })
  }

  handleChangeDestination = i => e => {
    this.setState({
      ...this.state,
      menuOptions: this.state.menuOptions.map((val, ind) => {
        if (ind === i) {
          if (e.target.value === 'voiceMail') {
            this.handleIVRVoicemailAPI({ ...val, destination: e.target.value })
          }
          return { ...val, destination: e.target.value }
        }
        return val
      })
    })
  }

  handleIVRVoicemailAPI = async record => {
    const payload = {}
    payload[`ivr_status`] = true
    payload[`digit_numbers`] = [+record?.key + 1]
    payload[`ivr_ids`] = [+this.props.paramId]
    payload[`ivr_types`] = ['voicemail']
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/ivr-option`
    Axios.post(url, payload)
      .then(res => {
        CommonNotify(`successfully added`, 'success')
        this.handleGetNumberIVR()
      })
      .catch(err => {
        if (err?.response?.data?.errors && err?.response?.data?.errors?.length)
          return err?.response?.data?.errors.map(error => {
            return CommonNotify(
              error || err?.response?.message || `Something went to wrong!`,
              'warning'
            )
          })
      })
  }

  handleChangeOutsideNumber = (i, value) => {
    this.setState({
      ...this.state,
      menuOptions: this.state.menuOptions.map((val, ind) => {
        if (ind === i) {
          return { ...val, value: value }
        }
        return val
      })
    })
  }

  handleSaveOutsideNumber = async i => {
    const record = this.state.menuOptions[i]
    const value = record?.value
    if (!value) {
      CommonNotify(`Phone number is required`, 'warning')
      return
    } else if (value?.length < 11) {
      CommonNotify(`Please enter valid phone number`, 'warning')
    }
    const payload = {}
    payload[`ivr_status`] = true
    payload[`digit_numbers`] = [+record?.key + 1]
    payload[`ivr_ids`] = [+value]
    payload[`ivr_types`] = ['App\\UserPhoneNumber']
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${this.state.numberId}/ivr-option`
    Axios.post(url, payload)
      .then(res => {
        CommonNotify(`successfully added`, 'success')
        this.handleGetNumberIVR()
      })
      .catch(err => {
        if (err?.response?.data?.errors && err?.response?.data?.errors?.length)
          return err?.response?.data?.errors.map(error => {
            return CommonNotify(
              error || err?.response?.message || `Something went to wrong!`,
              'warning'
            )
          })
      })
  }

  selectGreetingHandler = (e, data) => {
    const { greetingType } = this.state

    this.setState({
      ...this.state,
      greetingType: data.value
    })
  }

  onCancel = async () => {
    const { nameFromAPI } = this.state
    this.setState({ numberName: nameFromAPI, showEditButton: false })
  }

  selectOptionHandler = label => {
    const { assignToData } = this.state
    switch (label) {
      case 'platform':
        return assignToData.platform.options
        break
      case 'team':
        return assignToData.team.options
        break
      case 'user':
        return assignToData.user.options
        break
      case 'voicemail':
        return assignToData.voicemail.options
        break
      default:
        return assignToData.platform.options
        break
    }
  }
  selectOptionHandlerPhoneMenu = label => {
    const { assignToData } = this.state
    switch (label) {
      case 'platform':
        return assignToData.platform.options
        break
      case 'team':
        return assignToData.team.options
        break
      case 'user':
        return assignToData?.IVRUserData?.options

        break
      case 'voicemail':
      case 'voiceMail':
        return assignToData.voicemail.options
        break
      default:
        return assignToData.platform.options
        break
    }
  }

  selectValueHandler = label => {
    const { assignToData } = this.state
    switch (label) {
      case 'platform':
        return assignToData.platform.options
        break
      case 'team':
        return assignToData.team.options
        break
      case 'user':
        return assignToData.user.options
        break
      case 'voicemail':
        return assignToData.voicemail.options
        break
      default:
        return assignToData.platform.options
        break
    }
  }

  onAssignedToTeam = async data => {
    const { assignedData } = this.state

    this.props.loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/assign-incoming-numbers-to-team`

    const postData = {
      phone_number_id: this.state.numberId,
      team_id: data.value
    }

    Axios.post(url, postData)
      .then(res => {
        this.props.loading(false)
        const name = res.data.data.name
        // CommonNotify(`Number Assigned To Team ${name} successfully`, 'success')
      })
      .catch(err => {
        this.props.loading(false)
        CommonNotify('Unable to assign this number at the moment.')
      })
  }

  onAssignedToUsers = data => {

    const postData = {
      type: 'member',
      member_id: [...this.state.selectedMembers]
    }
    this.assignTheNumber(postData)
  }

  assignTheNumber = async data => {
    const { numberId } = this.state
    this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${numberId}/assign`

    Axios.post(url, data)
      .then(res => {
        this.props.loading(false)
        CommonNotify(`Number Assigned To ${data.type} successfully`, 'success')
      })
      .catch(err => {
        this.props.loading(false)
        CommonNotify('Unable to assign this number at the moment.')
      })
  }
  UpdatePhoneState = async data => {
    const { numberId } = this.state
    this.props.loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${numberId}/update-IVR-status`

    Axios.put(url, data)
      .then(res => {
        this.props.loading(false)
        CommonNotify(`Number status updated successfully`, 'success')
      })
      .catch(err => {
        this.props.loading(false)
        CommonNotify('Unable to update phone status.')
      })
  }

  onAssignedToWidget = async data => {
    this.props.loading(true)

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/assign-incoming-numbers-to-member`

    const postData = {
      phone_number_id: this.state.numberId,
      widget_id: data.value
    }

    Axios.post(url, postData)
      .then(res => {
        this.props.loading(false)
        // CommonNotify('Number Assigned To widget successfully', 'success')
      })
      .catch(err => {
        this.props.loading(false)
        CommonNotify('Unable to assign this number at the moment.')
      })
  }

  onPostDataSaveHandler = (e, data) => {
    const { label } = this.state.assignToData
    var postData = {}
    switch (label) {
      case 'team':
        postData = {
          type: 'team',
          team_id: [data.value]
        }
        this.setState({
          ...this.state,
          selectedMembers: data.value
        })
        this.assignTheNumber(postData)
        break
      case 'user':
        this.setState(
          {
            ...this.state,
            defaultAssigned: this.state.assignToData.label,
            selectedMembers: data.value
          },
          () => this.onAssignedToUsers()
        )
        break
      case 'platform':
        postData = {
          type: 'widget',
          widget_id: data.value
        }
        this.setState({
          ...this.state,
          // defaultAssigned: data.value
          selectedMembers: data.value
        })
        this.assignTheNumber(postData)
        break
      default:
        break
    }
  }

  handleOpenModal = () => {
    this.setState({
      addUserFlag: !this.state.addUserFlag
    })
  }

  handleGreetingModal = () => {
    this.setState({
      voiceModal: !this.state.voiceModal
    })
  }
  handleManageModal = () => {
    switch (this.state.voiceModalType) {
      case 'file':
        return (
          <>
            <div className="upload-btn-wrapper">
              <button
                className="btn btn-white mb-1"
                // onClick={}
              >
                Select File
              </button>
              <input
                type="file"
                onChange={this.handleBeforeVoiceFile}
                className="form-control"
                name="upload_file"
                style={{ padding: '2px' }}
                accept=".wav"
              />
              <div className="form-row">
                <audio
                  className="w-100"
                  controls
                  src={this.state.beforeVoiceMail}
                  type="*"
                />
              </div>
            </div>
            <div className="user_assing_btn">
              {this.state.voiceMailUpdated && (
                <>
                  <button
                    onClick={this.saveBeforeVoiceMail}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Upload</p>{' '}
                  </button>
                </>
              )}
            </div>
          </>
        )
      case 'audio':
        return (
          <div>
            <AudioReactRecorder
              state={this.state?.recordState}
              onStop={e => this?.onStop(e)}
              type="audio/wav"
              canvasWidth="300"
              canvasHeight="50"
            />
            {this.state?.recordState === 'stop' || !this.state?.recordState ? (
              <button
                className="ui button btn-white"
                onClick={e => this.start(e)}
              >
                <RadioButtonCheckedIcon /> Start Record
              </button>
            ) : (
              <button
                className="ui button btn-white"
                onClick={e => this.stop(e)}
              >
                <StopCircleIcon /> Stop Record
              </button>
              // <button className="ui button btn-white" onClick={this.start}>
              //   <RadioButtonCheckedIcon /> Start Record
              // </button>
            )}
            <div className="user_assing_btn">
              {this.state?.voiceMailUpdated &&
                (this.state?.greetingBlob || this.state?.blob) && (
                  <>
                    <div className="form-row">
                      <audio
                        className="w-100"
                        controls
                        src={
                          this.state.voiceRecordType === 'greeting'
                            ? this.state?.greetingBlob
                            : this.state?.blob
                        }
                        type="*"
                      />
                    </div>
                    <button
                      onClick={this.saveBeforeVoiceMail}
                      className="ui button btn-grey"
                      style={{
                        backgroundColor: '#0071eb',
                        marginTop: 10,
                        color: '#ffffff'
                      }}
                      disabled={this.state?.voiceRecordFlag}
                    >
                      {' '}
                      <p style={{ color: '#fff', fontSize: 12 }}>Upload</p>{' '}
                    </button>
                  </>
                )}
            </div>
          </div>
        )
      case 'greeting':
        return (
          <div>
            {this.state.defaultPlayBack &&
            this.state.defaultPlayBack?.length > 0 ? (
              <div>
                <h4>
                  Click to select any of one from Below list to set default
                  greeting
                </h4>
                <ul>
                  {this.state.defaultPlayBack?.map((list, index) => {
                    let name = list?.path?.split('/')
                    name = name[name?.length - 1]
                    return (
                      <li
                        key={index}
                        onClick={() => this.saveDefaultVoiceMail(true, list)}
                      >
                        {name}
                      </li>
                    )
                  })}
                </ul>
                {/* <button
                  onClick={this.saveBeforeVoiceMail}
                  className="ui button btn-grey"
                  style={{
                    backgroundColor: '#0071eb',
                    marginTop: 10,
                    color: '#ffffff'
                  }}
                >
                  {' '}
                  <p style={{ color: '#fff', fontSize: 12 }}>Upload</p>{' '}
                </button> */}
              </div>
            ) : (
              <span>Default template not available.</span>
            )}
          </div>
        )
      // return this.handleGreetingModal()

      default:
        return <div>Something went to wrong...</div>
    }
  }

  handleGetNumberIVR = async () => {
    const parsed = this.props.paramId
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${parsed}/IVR`
    if (parsed) {
      await Axios.get(url)
        .then(async res => {
          if (res.data) {
            this.props.loading(false)
            const data = await res.data.data
            this.handleSetIVRSetting(data)
            if (data) {
              this.setState({
                ...this.state,
                phoneMenuStatus: data?.ivr_status === 0 ? false : true,
                ivrDetails: data
              })
            }
          }
        })
        .catch(err => {
          this.props.loading(false)
        })
    }
  }

  handleSetIVRSetting = data => {
    if (data && Object.keys(data)?.length) {
      if (data?.ivr_settings && data?.ivr_settings?.length) {
        let ids = [],
          menuOptionData = []
        const dataList =
          data?.ivr_settings &&
          data?.ivr_settings?.map(list => {
            if (list?.ivr_type === 'App\\UserPhoneNumber') {
              menuOptionData.push({
                key: (list?.number - 1)?.toString(),
                destination: 'outsideMember',
                value: list?.ivr_id?.toString(),
                phone_number_id: list?.phone_number_id?.toString(),
                ids: list?.id,
                isDelete: true
              })
            }
            if (list?.ivr_type === 'voicemail') {
              menuOptionData.push({
                key: (list?.number - 1)?.toString(),
                destination: 'voiceMail',
                value: list?.ivr_id?.toString(),
                phone_number_id: list?.phone_number_id?.toString(),
                ids: list?.id,
                isDelete: true
              })
            }
            if (list?.ivr_type === 'App\\User') {
              menuOptionData.push({
                key: (list?.number - 1)?.toString(),
                destination: 'user',
                value: list?.ivr_id?.toString(),
                phone_number_id: list?.phone_number_id?.toString(),
                ids: list?.id,
                isDelete: true
              })
            }
            if (list?.ivr_type === 'App\\Team') {
              menuOptionData.push({
                key: (list?.number - 1)?.toString(),
                destination: 'team',
                value: list?.ivr_id?.toString(),
                phone_number_id: list?.phone_number_id?.toString(),
                ids: list?.id,
                isDelete: true
              })
            }
          })
        this.setState({
          menuOptions: menuOptionData,
          selectedKeys: menuOptionData?.map(list => {
            return list?.key
          }),
          ivrDetails: data
        })
      }
    }
    return null
  }

  handleChangeNumber = e => {
    if (e.target?.value && e.target?.value?.trim() === 'Delete Primary') {
      this.setState({
        ...this.state,
        deleteNumberFlag: true
      })
    } else {
      this.setState({
        ...this.state,
        deleteNumberFlag: false
      })
    }
  }

  handleDeleteNumber = e => {
    if (this.state.deleteNumberFlag) {
      this.handleDeleteNumberAPI()
    } else {
      CommonNotify('Please enter Delete Primary text properly.')
    }
  }

  handleDeleteNumberAPI = async () => {
    const parsed = this.props.paramId
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${parsed}`
    if (parsed) {
      const data = new FormData()
      await Axios.delete(url, { data: data })
        .then(async res => {
          if (res.data) {
            this.props.loading(false)
            const data = await res.data.data
          }
        })
        .catch(err => {
          this.props.loading(false)
        })
    }
  }

  voiceMailFn = () => {
    return (
      <Menu
        anchorEl={this.state.grettingDropdown}
        open={this.state.grettingDropdown ? true : false}
        onClose={() => this.handleVoicemailClose(false)}
        elevation={1}
      >
        <MenuItem onClick={() => this.handleVoicemailClose('file')}>
          <FolderCopyOutlinedIcon />{' '}
          <span className="ml-2"> Upload a file</span>
        </MenuItem>
        <MenuItem onClick={() => this.handleVoicemailClose('audio')}>
          <MicOutlinedIcon /> <span className="ml-2"> Record your audio</span>
        </MenuItem>

        <MenuItem
          onClick={() => this.handleVoicemailClose('voicemailGreeting')}
        >
          <VoicemailOutlinedIcon />{' '}
          <span className="ml-2"> Default template </span>
        </MenuItem>
      </Menu>
    )
  }

  handleAddURl = async () => {
    if (this.state?.webhookURL) {
      const parsed = this.props.paramId
      let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${parsed}/outgoing-webhook`
      if (this.state?.outgoing_webhook?.id) {
        url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/outgoing-webhook/${this.state?.outgoing_webhook?.id}`
        const data = { url: this.state?.webhookURL }
        await Axios.put(url, data)
          .then(async res => {
            CommonNotify('URL Updated successfully.')
          })
          .catch(err => {
            CommonNotify(
              err?.response?.data?.errors[0] || 'Something went to wronng!'
            )
          })
      } else {
        const data = { url: this.state?.webhookURL }
        await Axios.post(url, data)
          .then(async res => {
            CommonNotify('URL added successfully.')
          })
          .catch(err => {
            CommonNotify(
              err?.response?.data?.errors[0] || 'Something went to wronng!'
            )
          })
      }
    } else {
      CommonNotify('Please enter URL first')
    }
  }

  handleUpdateNotes = async () => {
    if (this.state?.userNotes) {
      const parsed = this.props?.paramId
      const url = `${process.env.REACT_APP_BASE_APP_URL}//api/v1/number/${parsed}/note`
      const data = { note: this.state?.userNotes }
      await Axios.post(url, data)
        .then(async res => {
          this.setState({
            userNotesUpdated: false
          })
          CommonNotify('Notes added successfully.')
        })
        .catch(err => {
          CommonNotify(
            err?.response?.data?.errors[0] || 'Something went to wronng!'
          )
        })
      // }
    } else {
      CommonNotify('Notes value is required')
    }
  }

  updateVoicemalDelayValue = async () => {
    // write API code here
    const parsed = this.props?.paramId
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${parsed}/before-voicemail`
    const data = { duration_before_voicemail: this.state.voicemailDelay }
    await Axios.post(url, data)
      .then(async res => {
        CommonNotify('Updated successfully.')
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors[0] || 'Something went to wronng!'
        )
      })
  }

  handleRestoreDefaultHoldMusic = async () => {
    // write API code here
    const parsed = this.props?.paramId
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${parsed}/hold-music`
    const data = { restore_default: 1 }
    await Axios.post(url, data)
      .then(async res => {
        CommonNotify('Restore Hold Music successfully.')
        this.getNumberAPI(parsed)
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors[0] || 'Something went to wronng!'
        )
      })
  }

  handleHoldMusicVoiceFile = async event => {
    const file = event?.target?.files?.[0]
    const parsed = this.props?.paramId
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${parsed}/hold-music`
    const data = new FormData()
    data.append('restore_default', 0)
    data.append('hold_music_url', file)

    await Axios.post(url, data)
      .then(async res => {
        CommonNotify('New Hold Music Updated Successfully.')
        this.getNumberAPI(parsed)
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors[0] || 'Something went to wronng!'
        )
      })
  }

  render() {
    const {
      voiceMail,
      greetingData,
      shareToggle,
      deleteNumber,
      numberStatus,
      afterCallMessage,
      missedCallMessage,
      afterSmsMessage,
      afterCallMessageText,
      missedCallMessageText,
      afterSmsMessageText,
      voicemailDelay,
      speechToTextStatus
    } = this.state

    const css = `
      .innertab-wrapper{
        display:none;
      }
  `

  const voicemailData=[
    {key :  "Always",
      text   :   "Always",
      value :   0},
    {key :  "When busy",
      text   :   "When busy",
      value :   1},
    {key :  "No answer for 60 seconds",
      text   :   "No answer for 60 seconds",
      value :   2},
    {key :  "Do not forward to voicemail",
      text   :   "Do not forward to voicemail",
      value :   3},
  ]
    return (
      <div className="account-settings" style={{ marginTop: '32px' }}>
        <style>{css}</style>
        <Modal
          centered
          className="modal-position"
          show={this.state.voiceModal}
          onHide={this.handleGreetingModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.voiceRecordType === 'greeting'
                ? 'Greeting Message'
                : 'Voicemail'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.handleManageModal()}</Modal.Body>
        </Modal>
        <div className="phnumberHeading">{this.state.phoneNumber}</div>
        <section className="card-manage-number">
          <div
            className="campaign-sec"
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '36px'
            }}
          >
            <div className="assing_opt" style={{ flex: 3 }}>
              <h4 className="bold-text accordion-title">
                What do you call this number?
              </h4>
              <p className="card-desc subtext accordion-desc">
                We suggest use a unique name that will help you to easily
                identify this number.
              </p>
            </div>

            <div className="assing_opt" style={{ flex: 1 }}>
              <CommonInput
                style={{ width: '100%' }}
                value={this.state.numberName}
                placeholder="Enter a Name"
                name="SetCampaign"
                onChange={e => {
                  this.setState({
                    numberName: e.target.value,
                    showEditButton: true
                  })
                }}
                defaultValue={this.state.numberName}
                type="text"
              />
              {this.state.showEditButton ? (
                <div>
                  <button
                    onClick={this.renameNumber}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 20,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                  <button
                    onClick={this.onCancel}
                    className="ui button btn-grey"
                    style={{ marginTop: 20 }}
                  >
                    {' '}
                    <p style={{ color: 'black', fontSize: 12 }}>Cancel</p>{' '}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </section>

        <section className="card-manage-number" id="phone-status">
          <div className="campaign-sec">
            {this.state.numberStatus == null ? null : (
              <NodeToggle
                handleDataRef={this.handleStatus}
                dataToggle={numberStatus}
                activeDefault={numberStatus?.status}
              />
            )}
          </div>
        </section>

        <section className="card-manage-number" id="call-recording">
          <div className="campaign-sec">
            {this.state.callToggle === null
              ? null
              : this.state.callToggle.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <NodeToggle
                        handleDataRef={this.handleDataRef}
                        dataToggle={item}
                        activeDefault={item.status}
                      />
                    </React.Fragment>
                  )
                })}
          </div>
        </section>

        <section className="card-manage-number" id="user">
          <div className="campaign-sec">
            {this.state.shareToggle == null ? null : (
              <>
                <div className="toggle-group">
                  <Row className="align-items-center">
                    <Col md={8}>
                      {(shareToggle.callTitle || shareToggle.callDesc) && (
                        <div className="toggle-content">
                          {shareToggle.callTitle && (
                            <h3 className="call-title">
                              {shareToggle.callTitle}
                            </h3>
                          )}
                          {shareToggle.callDesc && (
                            <p className="call-description">
                              {shareToggle.callDesc}
                            </p>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col md={4}>
                      <div className="text-right">
                        <button
                          className="btn btn-white"
                          onClick={this.handleOpenModal}
                        >
                          Add Users
                        </button>
                        {this.state.addUserFlag && (
                          <>
                            <div
                              className="overlay-dropdown"
                              onClick={this.handleOpenModal}
                            ></div>
                            <div
                              className="assing_opt mt-2"
                              style={{ flex: 1 }}
                            >
                              <Dropdown
                                placeholder={`Select Memebrs `}
                                fluid
                                selection
                                open={this.state.addUserFlag}
                                multiple={true}
                                options={this.state?.AllShareMember || []}
                                onChange={this.onShareidsHandler}
                                value={this.state.sharedIds}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
          <div className="campaign-sec">
            {
              <>
                <div className="assing_opt user-number-table">
                  <Table singleLine>
                    <Table.Header className="top-table-header">
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Access</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state?.shareAccessList &&
                        this.state?.shareAccessList?.length > 0 &&
                        this.state?.shareAccessList.map((data, ind) => 
                       this.state?.owner?.id===data?.user_id ? 
                           <Table.Row className="table-content-row" key={ind}>
                           <Table.Cell>
                             <img src={data?.profile_image} alt="" />
                             {data?.name || ''}
                           </Table.Cell>
                           <Table.Cell>
                             {/* {data?.role === 'Full Access' ? 'Admin':'Member'} */}
                            Owner
                           </Table.Cell>
                         
                         </Table.Row>:
                           <Table.Row className="table-content-row" key={ind}>
                              <Table.Cell>
                                <img src={data?.profile_image} alt="" />
                                {data?.name || ''}
                              </Table.Cell>
                              <Table.Cell>
                                {/* {data?.role === 'Full Access' ? 'Admin':'Member'} */}
                                <select
                                  defaultValue={
                                    data?.role_type === 'Full Access' ? 2 : 1
                                  }
                                  className="w-full mb-1 text-gray-900 px-1 py-1 text-gray-700 rounded"
                                  id="available_slot_time"
                                  onChange={() =>
                                    this.handleUpdateShareRole(data)
                                  }
                                >
                                  <option value={2}>Admin</option>
                                  <option value={1}>Member</option>
                                </select>
                              </Table.Cell>
                          <Table.Cell>
                                <div onClick={e => this.handleClick(e, data)}>
                                  <MoreHorizIcon />
                                </div>
                                <Menu
                                  anchorEl={this.state.anchorEl}
                                  open={this.state.anchorEl ? true : false}
                                  onClose={this.handleClose}
                                  elevation={1}
                                >
                                  <MenuItem
                                    onClick={() => this.handleDelete(data)}
                                  >
                                    <DeleteIcon /> Delete
                                  </MenuItem>
                                </Menu>
                              </Table.Cell>
                            </Table.Row>
                        
                        )}
            
                    </Table.Body>
                  </Table>
                </div>
              </>
            }
          </div>
        </section>
        {/* Notes Section */}

        <section className="card-manage-number" id="notes">
          <div className="campaign-sec">
            <div className="toggle-group">
              <Row className="align-items-center">
                <Col md={12}>
                  <div className="toggle-content">
                    <h3 className="call-title">Notes</h3>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="campaign-sec">
            <div className="assing_opt">
              <Textarea
                onChange={e =>
                  this.setState({
                    userNotes: e.target.value,
                    userNotesUpdated: true
                  })
                }
                value={this.state?.userNotes}
              ></Textarea>
              <div className="user_assing_btn">
                {this.state?.userNotesUpdated && (
                  <button
                    onClick={this.handleUpdateNotes}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* // internation calling and number */}
        {/* <section className="card-manage-number">
          <div className="campaign-sec">
            {this.state.internationalCall == null ? null : (
              <>
                <div className="toggle-group">
                  <div className="toggle-wrapper">
                    {(internationalCall.callTitle ||
                      internationalCall.callDesc) && (
                      <div className="toggle-content">
                        {internationalCall.callTitle && (
                          <h3 className="call-title">
                            {internationalCall.callTitle}
                          </h3>
                        )}
                        {internationalCall.callDesc && (
                          <p className="call-description">
                            {internationalCall.callDesc}{' '}
                            <a href="#">Check rates</a>
                          </p>
                        )}
                      </div>
                    )}
                    <div className="toggle-holder">
                      <Switch />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section> */}
        {/* Vocie mail components */}

        <section className="card-manage-number" id="voicemail">
        <div className="campaign-sec d-flex justify-content-between mb-3">
            {voiceMail?.status == null ? null : (
              <div>
                <h4 className="bold-text"> Voicemail</h4>
                <p className="subtext"> Customize this phone number's voicemail</p>
              </div>
            )}
          
            <div className='mb-2'>
            <div
                              className="assing_opt mt-2"
                              style={{ flex: 1 }}
                            >
                              <Dropdown
                                placeholder={`Select voicemail`}
                                fluid
                                closeOnChange={true}
                                selection
                                value={this.state?.voiceMailStatus}
                                onChange={(e,value)=>{
                                  this.voicemailHandler(value?.value)}} 
                                options={[...voicemailData]}
                              />
                 
                            </div>
                      </div>
          </div>

            <div className="assing_opt">
              <div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <h6>Voicemail audio</h6>
                    <span>What is played when calls are missed</span>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="upload-btn-wrapper">
                      <button
                        className="btn btn-white"
                        onClick={e => this.handleVocieMailClick(e, 'voiceMail')}
                      >
                        Change
                      </button>
                      {this.voiceMailFn()}
                      {/* <input
                      type="file"
                      onChange={this.handleBeforeVoiceFile}
                      className="form-control"
                      name="upload_file"
                      style={{ padding: '2px' }}
                      accept=".wav"
                    /> */}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <audio
                    className="w-100"
                    controls
                    src={
                      this.state.blob
                        ? this.state.blob
                        : this.state.beforeVoiceMail
                    }
                    type="*"
                  />
                </div>
                {/* <div className="form-row pt-3 mt-3 border-top">
                  <div className="col-md-6">
                    <h6>Transcribe voicemails</h6>
                    <h6>Use speech-to-text to transcribe viocemains</h6>
                  </div>
                  <div className="col-md-6 text-right">
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={voiceMail?.status}
                          onChange={e =>
                            this.handleVoiceMail(e?.target?.checked, null)
                          }
                        />
                      }
                    />
                  </div>
                </div> */}
              </div>

              <div className="user_assing_btn">
                {this.state.voiceMailUpdated && (
                  <button
                    onClick={this.saveBeforeVoiceMail}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                )}
              </div>
            </div>
         
          {/* // Voicemail Delay */}
            <div className="campaign-sec mt-4">
              <div
                className="assing_opt"
                style={{
                  marginBottom: '25px'
                }}
              >
                <h4 className="bold-text accordion-title">Voicemail Delay</h4>
                <p className="card-desc subtext accordion-desc">
                  Time delay before calls are transferred to voicemail.
                </p>
              </div>

              <div className="assing_opt">
                <h6 className="accordion-title">
                  Delay until voicemail (Seconds)
                </h6>
                <CommonInput
                  style={{ width: '100%' }}
                  value={voicemailDelay || 0}
                  placeholder="Enter Delay second"
                  name="SetCampaign"
                  type="number"
                  onChange={e => {
                    this.setState({
                      voicemailDelay: e.target.value
                    })
                  }}
                  defaultValue={voicemailDelay || 0}
                />
                <div>
                  <button
                    onClick={this.updateVoicemalDelayValue}
                    className="ui button btn-blue"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 20,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                </div>
              </div>
            </div>
        </section>
        {/* Greeting message components */}
        <section className="card-manage-number" id="greeting-message">
          <div className="campaign-sec">
            <div
              className="assing_opt"
              style={{
                flex: 3,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <label
                htmlFor="assing_dropdwon"
                className="bold-text accordion-title"
              >
                Greeting Message
              </label>
              <div className="">
                <NodeToggle
                  handleDataRef={this.handleUpdateGreetingMessageStatus}
                  dataToggle={greetingData}
                  activeDefault={greetingData?.status}
                />
              </div>
            </div>
          </div>
          {greetingData?.status ? (
            <div className="assing_opt">
              <div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    {/* <h6>Greeting message</h6> */}
                    <span className="call-description">
                      Example: Hi! Thanks for calling. For sales, press 1 or say
                      sales. For support, press 2 or say support
                    </span>
                  </div>
                  <div
                    className="form-group col-md-6 text-right"
                    style={{ textAlign: 'end' }}
                  >
                    <button
                      className="btn btn-white"
                      onClick={e => this.handleVocieMailClick(e, 'greeting')}
                    >
                      Set a greeting message
                    </button>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="upload-btn-wrapper">
                      {this.voiceMailFn()}
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <audio
                    className="w-100"
                    controls
                    src={
                      this.state?.greetingBlob
                        ? this.state?.greetingBlob
                        : this.state.greetingVoiceMail
                    }
                    type="*"
                  />
                </div>
              </div>

              <div className="user_assing_btn">
                {this.state.voiceMailUpdated && (
                  <button
                    onClick={this.saveBeforeVoiceMail}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </section>

        {/* { Hold music} */}

        <section className="card-manage-number">
          <div className="campaign-sec">
            <div
              className="assing_opt"
              style={{
                flex: 3,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <label
                htmlFor="assing_dropdwon"
                className="bold-text accordion-title"
              >
                Hold Music
              </label>
            </div>
          </div>

          <div className="assing_opt">
            <div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <span className="call-description">
                    While on hold, callers will hear music which you can choose
                    to use the default or to customise. Uploaded files can be a
                    maximum of 3MB in size. Accepted file formats: mp3, aiff,
                    wav, flac.
                  </span>
                </div>
                <div className="form-group col-md-6">
                  <div className="upload-btn-wrapper"></div>
                </div>
              </div>
              <div className="form-row">
                <audio
                  className="w-100"
                  controls
                  src={
                    this.state?.holdBlob
                      ? this.state?.holdBlob
                      : this.state.holdMusic
                  }
                ></audio>
              </div>
            </div>

            <div className="user_assing_btn d-flex mt-3">
              <div className="upload-btn-wrappers">
                <Button primary className="btn btn-blue">
                  <p style={{ color: '#fff', fontSize: 12 }}>New Music</p>
                </Button>
                <input
                  type="file"
                  onChange={this.handleHoldMusicVoiceFile}
                  className="form-control"
                  name="upload_file"
                  style={{ padding: '2px' }}
                  accept=".wav,.flac,aiff,.mp3"
                />
              </div>
              <Button
                onClick={this.handleRestoreDefaultHoldMusic}
                primary
                className="btn btn-blue"
              >
                {' '}
                <p style={{ color: '#fff', fontSize: 12 }}>
                  Restore default
                </p>{' '}
              </Button>
            </div>
          </div>
        </section>

        {/* Speech to text call components */}
        <section className="card-manage-number" id="greeting-message">
          <div className="campaign-sec">
            <div
              className="assing_opt"
              style={{
                flex: 3,
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <label
                htmlFor="assing_dropdwon"
                className="bold-text accordion-title"
              >
                Call Transcription
              </label>
              <div className="">
                {this.state?.dataLoad && (
                  <NodeToggle
                    handleDataRef={this.handleUpdateCallTranscriptionStatus}
                    dataToggle={speechToTextStatus}
                    activeDefault={speechToTextStatus?.status}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="assing_opt">
            <div>
              <div className="form-row">
                <div
                  className="form-group col-md-6"
                  style={{ marginTop: '-50px' }}
                >
                  <span className="call-description">
                    Read details from your calls without the need to listen to
                    the entire call recording.
                  </span>
                </div>
                <div
                  className="form-group col-md-6 text-right"
                  style={{ textAlign: 'end' }}
                ></div>
                <div className="form-group col-md-6">
                  <div className="upload-btn-wrapper"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  Forward calls to  component  */}
        <section className="card-manage-number" id="call-forwarding">
          <div
            className="campaign-sec"
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '16px'
            }}
          >
            <div className="assing_opt" style={{ flex: 3 }}>
              <label
                htmlFor="assing_dropdwon"
                className="bold-text accordion-title"
              >
                Call Routing
              </label>
              <p className="card-desc subtext accordion-desc">
                Tell us where should we route incoming calls made to your
                number.
              </p>
            </div>
            <div className="assing_opt" style={{ flex: 1 }}>
              <Dropdown
                placeholder="Select Option"
                fluid
                selection
                options={AssignTo}
                onChange={this.onAssingToHandler}
                value={this.state.defaultAssignTo}
              />
            </div>
          </div>

          {this.state.defaultAssignTo !== 'phoneMenu' &&
          this.state.defaultAssignTo?.toString()?.toLowerCase() !==
            'voicemail' ? (
            <div
              className="campaign-sec"
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '16px'
              }}
            >
              {this.state.showAssignValues &&
              this.state.defaultAssignTo !== 'phoneMenu' &&
              this.state.defaultAssignTo !== 'ivr' ? (
                <>
                  <div className="assing_opt" style={{ flex: 3 }}>
                    <label htmlFor="assing_dropdwon"></label>
                  </div>
                  {this.state.assignToData.label === 'External Number' ? (
                    <div className="assing_opt" style={{ flex: 1 }}>
                      <ReactPhoneInput />
                    </div>
                  ) : this.state.assignToData.label === 'voicemail' ? null : (
                    <div className="assing_opt" style={{ flex: 1 }}>
                      <Dropdown
                        placeholder={`Select ${this.state.assignToData.label} `}
                        fluid
                        selection
                        multiple={this.state.assignToData.label === 'user'}
                        options={
                          this.state.showAssignValues
                            ? this.selectOptionHandler(
                                this.state.assignToData.label
                              )
                            : null
                        }
                        onChange={this.onPostDataSaveHandler}
                        value={this.state.selectedMembers}
                      />
                    </div>
                  )}
                </>
              ) : null}
            </div>
          ) : null}
          {this.state.defaultAssignTo === 'phoneMenu' ||
          this.state.defaultAssignTo === 'ivr' ? (
            <div className="campaign-sec">
              <div className="toggle-group mb-3">
                <div className="toggle-wrapper align-items-center">
                  <div className="toggle-content">
                    <h3 className="call-title mb-2">Phone menu</h3>
                    <p className="call-description">
                      Configure a custom phone menu callers can navigate
                    </p>
                  </div>
                  {/*  <Badge>
                    {' '}
                    {this.state.phoneMenuStatus ? 'Enable' : 'Disabled'}
                  </Badge> */}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <h6>Menu options</h6>
                  <span className="call-description">
                    Option are triggered by keypad and voice commands, and can
                    route to other team members in you workspace, or even to
                    external phone numbers.
                  </span>
                </div>
                <div className="form-group col-md-12">
                  <Table singleLine className="phone-menu-table">
                    <Table.Header className="top-table-header">
                      <Table.Row>
                        <Table.HeaderCell>Key</Table.HeaderCell>
                        {/* <Table.HeaderCell>Voice</Table.HeaderCell> */}
                        <Table.HeaderCell>Destination</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.menuOptions?.map((v, i) => (
                        <Table.Row className="table-content-row" key={i}>
                          <Table.Cell>
                            <div className="custom-select-control">
                              <select
                                name="optionKey"
                                className="form-control"
                                onChange={this.handleChangeKey(i)}
                                value={v.key}
                              >
                                {keyList.map(data => {
                                  return (
                                    <option
                                      disabled={this.state?.selectedKeys?.includes(
                                        data?.toString()
                                      )}
                                      key={data?.toString()}
                                      value={data}
                                    >
                                      {data}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          </Table.Cell>
                          {/* <Table.Cell>
                          <input className="form-control" />
                        </Table.Cell> */}
                          <Table.Cell>
                            <select
                              name="optionDestination"
                              className="form-control"
                              onChange={this.handleChangeDestination(i)}
                              value={v.destination}
                            >
                              <option value="team">Send to a team</option>
                              <option value="user">
                                Send to a team member
                              </option>
                              <option value="voiceMail">
                                Send to voice mail
                              </option>
                              <option value="outsideMember">
                                Forward to an outside number
                              </option>
                              {/* <option value="defaultAction">
                                Play an audio and then route to default action
                              </option> */}
                            </select>
                          </Table.Cell>
                          {v.destination === 'outsideMember' ? (
                            <Table.Cell style={{ display: 'flex' }}>
                              <PhoneInput
                                placeholder="Number"
                                className="text-color"
                                onlyCountries={this.resetWidgetCountries(
                                  this.props?.widgetCountries?.data
                                    ?.allowed_countries || []
                                )}
                                country="us"
                                value={v?.value}
                                onChange={value =>
                                  this.handleChangeOutsideNumber(i, value)
                                }
                              />
                              <span
                                onClick={() => this.handleSaveOutsideNumber(i)}
                              >
                                <img
                                  style={{ margin: '10px', cursor: 'pointer' }}
                                  src={saveIcon}
                                  alt="save"
                                />
                              </span>
                            </Table.Cell>
                          ) : v.destination === 'voiceMail' ? null : (
                            <Table.Cell>
                              <Dropdown
                                fluid
                                selection
                                options={this.selectOptionHandlerPhoneMenu(
                                  v.destination
                                )}
                                onChange={this.onAssingToHandlerPhoneMenu}
                                value={v?.value && +v?.value}
                                name={i}
                              />
                            </Table.Cell>
                          )}
                          <Table.Cell>
                            <DeleteIcon
                              className="cursor-pointer"
                              onClick={() => this.handleDeleteKey(i)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <Button
                    className="btn btn-link p-0"
                    onClick={this.handleAddOption}
                  >
                    <AddIcon /> Add another option
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
          <div
            className="campaign-sec"
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '16px'
            }}
          >
            <div className="user_assing_btn" style={{ flex: 3 }}></div>

            {/* {this.state.assignToData.label === 'user' && (
              <div className="user_assing_btn" style={{ flex: 1}}>
                <button onClick={this.onAssignedToUsers} className="ui button btn-grey" style={{backgroundColor: '#0071eb', marginTop: 10, color: '#ffffff'}}> <p style={{color: '#fff', fontSize: 12}}>Assign to selected members</p> </button> 
              </div>
            )}   */}
          </div>
        </section>

        {this.state.greeting && (
          <div className="general-content-holder-right">
            <CommonInput
              name="greeting"
              inputProps={{ accept: 'audio/*;capture=microphone' }}
              type="file"
              onChange={e => this.onFileChange(e)}
              accept="audio/wav"
            />
            <div className="audio-controls">
              <audio controls="controls" ref={this.audio}>
                <source
                  // src="http://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/alien_shoot.wav"
                  src={this.state.file === null ? null : this.state.file.name}
                  type="audio/wav"
                />
              </audio>
            </div>
          </div>
        )}

        {this.state.whisper && (
          <div className="greeting-check">
            {/* <TextArea placeholder="Call coming from Facebook" /> */}
            <div className="general-content-holder-right">
              <CommonInput
                name="whisper"
                inputProps={{ accept: 'audio/*;capture=microphone' }}
                type="file"
              />
            </div>
          </div>
        )}

        {this.state.callerId && (
          <div className="greeting-check">
            <CommonSelect
              name="callerId"
              placeholder="Select Caller Id"
              options={[
                'New Number',
                'Your verified number (+918888847498)',
                'LimeCall Number (default)'
              ]}
            />
          </div>
        )}

<WorkingHours
          nextStep={true}
          number={this.state.numberId}
          activeTab="Business Hours"
          loading={false}
          handleBeforeVoiceFile={this.handleBeforeVoiceFile}
          beforeVoiceMail={this.state.beforeVoiceMail}
          voiceMailUpdated={this.state.voiceMailUpdated}
          saveBeforeVoiceMail={this.saveBeforeVoiceMail}
        ></WorkingHours>

        <section className="card-manage-number" id="auto-response">
          <div className="campaign-sec">
            {afterCallMessage?.status == null ? null : (
              <NodeToggle
                handleDataRef={this.handleAfterCallMsg}
                dataToggle={afterCallMessage}
                activeDefault={afterCallMessage?.status}
              />
            )}
          </div>

          {afterCallMessage?.status && (
            <div className="campaign-sec">
              <div className="assing_opt">
                <Textarea
                  onChange={e =>
                    this.setState({
                      afterCallMessageText: e.target.value,
                      afterCallMessageUpdated: true
                    })
                  }
                  value={afterCallMessageText}
                ></Textarea>
                <div className="user_assing_btn">
                  {this.state.afterCallMessageUpdated && (
                    <button
                      onClick={this.handleAfterCallData}
                      className="ui button btn-grey"
                      style={{
                        backgroundColor: '#0071eb',
                        marginTop: 10,
                        color: '#ffffff'
                      }}
                    >
                      {' '}
                      <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </section>
        <section className="card-manage-number">
          <div className="campaign-sec">
            {missedCallMessage?.status == null ? null : (
              <NodeToggle
                handleDataRef={this.handleAfterMissedCallMsg}
                dataToggle={missedCallMessage}
                activeDefault={missedCallMessage?.status}
              />
            )}
          </div>

          {missedCallMessage?.status && (
            <div className="assing_opt">
              <Textarea
                onChange={e =>
                  this.setState({
                    missedCallMessageText: e.target.value,
                    missedCallMessageUpdated: true
                  })
                }
                value={missedCallMessageText}
              ></Textarea>
              <div className="user_assing_btn">
                {this.state.missedCallMessageUpdated && (
                  <button
                    onClick={this.handleAfterCallData}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                )}
              </div>
            </div>
          )}
        </section>
        <section className="card-manage-number">
          <div className="campaign-sec">
            {afterSmsMessage?.status == null ? null : (
              <NodeToggle
                handleDataRef={this.handleAfterSmsMsg}
                dataToggle={afterSmsMessage}
                activeDefault={afterSmsMessage?.status}
              />
            )}
          </div>

          {afterSmsMessage?.status && (
            <div className="assing_opt">
              <Textarea
                onChange={e =>
                  this.setState({
                    afterSmsMessageText: e.target.value,
                    afterSmsMessageUpdated: true
                  })
                }
                value={afterSmsMessageText}
              ></Textarea>

              <div className="user_assing_btn">
                {this.state.afterSmsMessageUpdated && (
                  <button
                    onClick={this.handleAfterCallData}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                )}
              </div>
            </div>
          )}
        </section>

        {/* // auto replay message feature status changes blocks for non-bussiness */}

        {/* Non Business After Text */}

        <section className="card-manage-number">
          <div className="campaign-sec">
            {this.state?.nonBusinessAfterText?.status == null ? null : (
              <NodeToggle
                handleDataRef={this.handleNonBusinessText}
                dataToggle={this.state?.nonBusinessAfterText}
                activeDefault={this.state?.nonBusinessAfterText?.status}
              />
            )}
          </div>

          {this.state?.nonBusinessAfterText?.status && (
            <div className="assing_opt">
              <Textarea
                onChange={e => {
                  let { nonBusinessAfterText } = this.state
                  nonBusinessAfterText.value = e.target.value
                  this.setState({
                    nonBusinessAfterText: nonBusinessAfterText,
                    nonBusinessAfterTextUpdated: true
                  })
                }}
                value={this.state?.nonBusinessAfterText?.value}
              ></Textarea>
              <div className="user_assing_btn">
                {this.state.nonBusinessAfterTextUpdated && (
                  <button
                    onClick={this.handleAfterCallData}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                )}
              </div>
            </div>
          )}
        </section>

        {/* Non Business After Call */}

        <section className="card-manage-number">
          <div className="campaign-sec">
            {this.state?.nonBusinessAfterCall?.status == null ? null : (
              <NodeToggle
                handleDataRef={this.handleNonBusinessCall}
                dataToggle={this.state?.nonBusinessAfterCall}
                activeDefault={this.state?.nonBusinessAfterCall?.status}
              />
            )}
          </div>

          {this.state?.nonBusinessAfterCall?.status && (
            <div className="assing_opt">
              <Textarea
                onChange={e => {
                  let { nonBusinessAfterCall } = this.state
                  nonBusinessAfterCall.value = e.target.value
                  this.setState({
                    nonBusinessAfterCall: nonBusinessAfterCall,
                    nonBusinessAfterCallUpdated: true
                  })
                }}
                value={this.state?.nonBusinessAfterCall?.value}
              ></Textarea>

              <div className="user_assing_btn">
                {this.state.nonBusinessAfterCallUpdated && (
                  <button
                    onClick={this.handleAfterCallData}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                )}
              </div>
            </div>
          )}
        </section>

        {/* Non Business After Voice */}
        <section className="card-manage-number">
          <div className="campaign-sec">
            {this.state?.nonBusinessAfterVoice?.status == null ? null : (
              <NodeToggle
                handleDataRef={this.handleNonBusinessVoice}
                dataToggle={this.state?.nonBusinessAfterVoice}
                activeDefault={this.state?.nonBusinessAfterVoice?.status}
              />
            )}
          </div>

          {this.state?.nonBusinessAfterVoice?.status && (
            <div className="assing_opt">
              <Textarea
                onChange={e => {
                  let { nonBusinessAfterVoice } = this.state
                  nonBusinessAfterVoice.value = e.target.value
                  this.setState({
                    nonBusinessAfterVoice: nonBusinessAfterVoice,
                    nonBusinessAfterVoiceUpdated: true
                  })
                }}
                value={this.state?.nonBusinessAfterVoice?.value}
              ></Textarea>

              <div className="user_assing_btn">
                {this.state.nonBusinessAfterVoiceUpdated && (
                  <button
                    onClick={this.handleAfterCallData}
                    className="ui button btn-grey"
                    style={{
                      backgroundColor: '#0071eb',
                      marginTop: 10,
                      color: '#ffffff'
                    }}
                  >
                    {' '}
                    <p style={{ color: '#fff', fontSize: 12 }}>Save</p>{' '}
                  </button>
                )}
              </div>
            </div>
          )}
        </section>

        {/* // new post lead URL */}
        <section className="card-manage-number">
          <div
            className="campaign-sec"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginBottom: '36px'
            }}
          >
            <div className="assing_opt" style={{ flex: 3 }}>
              <h4 className="bold-text accordion-title">Webhook URL</h4>
              <p className="card-desc subtext accordion-desc">
                Add webhook URL.
              </p>
            </div>

            <div
              className="assing_opt webhook-url mt-4"
              style={{ width: '100%' }}
            >
              <CommonInput
                style={{ width: '100%' }}
                value={this?.state?.webhookURL}
                placeholder="https://"
                name="url"
                onChange={e => {
                  this.setState({
                    webhookURL: e.target.value
                  })
                }}
                defaultValue={this?.state?.webhookURL}
                type="text"
              />
              <div className="ml-3">
                <button
                  onClick={this.handleAddURl}
                  className="ui button btn-grey rounded"
                  style={{
                    backgroundColor: '#0071eb',
                    color: '#ffffff'
                  }}
                >
                  {' '}
                  <p style={{ color: '#fff', fontSize: 12 }}>
                    {this.state?.outgoing_webhook?.id ? 'Update' : 'Add'}
                  </p>{' '}
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* // Delete phone components */}
        <section className="card-manage-number">
          <div className="campaign-sec">
            {this.state.deleteNumber == null ? null : (
              <>
                <div className="danger-zone mb-3">Danger Zone</div>
                <div className="toggle-group">
                  <div className="toggle-wrapper">
                    {(deleteNumber.callTitle || deleteNumber.callDesc) && (
                      <div className="toggle-content">
                        {deleteNumber.callTitle && (
                          <h3 className="call-title mb-2">
                            {deleteNumber.callTitle}
                          </h3>
                        )}
                        {deleteNumber.callDesc && (
                          <p className="call-description">
                            {deleteNumber.callDesc}{' '}
                            {`Primary * ${this.state.phoneNumber}`}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="delete-number-wrap">
                  <div className="delete-number">
                    <CommonInput
                      width="100%"
                      placeholder="Delete Primary"
                      onChange={this.handleChangeNumber}
                    />{' '}
                    <CommonButtons
                      content="Cancel Number"
                      background="white "
                      onClick={this.handleDeleteNumber}
                    />
                  </div>
                  <span>Enter "Delete Primary" to continue</span>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { widgetCountries: state.settings.widgetCountries }
}

export default connect(mapStateToProps, { getWidgetCountries })(Configure)
