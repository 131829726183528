import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import axios from 'axios'
import { Dimmer, Loader, Table } from 'semantic-ui-react'

export default function MeetingOverView({ date }) {
  const [values, setValues] = useState({})

  useEffect(() => {
    getMeetingStats()
  }, [])

  const getMeetingStats = () => {
    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/meetings-stats`
    axios
      .get(url)
      .then(res => {
        setValues(res?.data?.data)
      })
      .catch(error => {})
  }

  return (
    <div className="cardbox-wrapper deatil_box" >
      <div className="container-trial">
        <Row className="banner-description-wrapper planinfo-box justify-content-start align-items-stretch">
          <Col md={12}>
            <div
              className="banner-description-box h-100"
            >
              <h5 className="font-weight-bold mb-3">Meeting Distribution</h5>
              {values && values?.length > 0 ? (
                <Table className="meeting-distribution">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>ASSIGN</Table.HeaderCell>
                      <Table.HeaderCell>BOOKED</Table.HeaderCell>
                      <Table.HeaderCell>COMPLETE</Table.HeaderCell>
                      <Table.HeaderCell>CANCELLED</Table.HeaderCell>
                      <Table.HeaderCell>NO SHOW</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {values.map(item => (
                      <Table.Row>
                        <Table.Cell>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <img
                              style={{ marginRight: '15px' }}
                              width={30}
                              height={30}
                              src={item?.agent_image}
                              alt={'agent'}
                            />
                            <p>{item?.agent_name}</p>
                          </div>
                        </Table.Cell>
                        <Table.Cell>{item?.total_booked || 0}%</Table.Cell>
                        <Table.Cell>{item?.completed_rate || 0}%</Table.Cell>
                        <Table.Cell> {item?.canceled_rate || 0}%</Table.Cell>
                        <Table.Cell> {item?.missed_rate || 0}%</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : (
                <Loader active={true} style={{ marginTop: '15px' }} />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
