import Axios from 'axios'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Icon, Input, TextArea } from 'semantic-ui-react'
import { CommonNotify } from '../../common/CommonNotify'
import personal from '../../assets/images/personal-booking.jpg'
import user from '../../assets/images/user-info.jpg'
import installWidget from '../../assets/images/install-widget.jpg'
import virtualNumber from '../../assets/images/virtual-number.jpg'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import LinkIcon from '@mui/icons-material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CountryModal from '../../common/CountryModal'
import dummy from '../../assets/images/magnifier.svg'
import { getDialer } from '../../actions/dialer'
import callForward from '../../assets/images/Call forwarding.svg'
import callManager from '../../assets/images/call manager.svg'
import callSettings from '../../assets/images/Call settings.svg'
import fromTracking from '../../assets/images/Form tracking.svg'
import qualificationSetting from '../../assets/images/Qualification settings.svg'
import referFriend from '../../assets/images/refer friend.svg'
import rewards from '../../assets/images/rewards.svg'
import mobileAPP from '../../assets/images/Mobile app.svg'
import { Col, Row } from 'react-bootstrap'

function PlanInfo({
  subscription,
  openSubscriptionModal,
  accountInfo,
  accountButtonText,
  profileData,
  widgetData,
  dialerStatus
}) {
  const [feedback, setFeedback] = useState({})
  const [sentFlag, setSentFlag] = useState(false)
  const [openCountry, setOpenCountry] = useState(false)

  const navigate =useHistory()

  const handleChange = (name, value) => {
    setFeedback({
      ...feedback,
      [name]: value
    })
  }

  const onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }

  const handleSendMessage = async () => {
    if (!feedback?.title) {
      CommonNotify('Type of issue title is required.')
      return
    }
    if (!feedback?.message) {
      CommonNotify('Message value is required.')
      return
    }
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/feedback`
    const postData = {
      subject: feedback?.title,
      message: feedback?.message
    }
    Axios.post(URL, postData)
      .then(res => {
        setSentFlag(true)
        CommonNotify('Feedback sent successfully.')
      })
      .catch(err => {
        CommonNotify(
          err?.response?.data?.errors?.[0] || 'Something went to wrong!'
        )
      })
  }

  const { data } = subscription || {}
  const status = JSON.parse(localStorage.getItem('phone_numbers'))
  const widgetStatus = JSON.parse(localStorage.getItem('widget_active'))

  const account = JSON.parse(localStorage.getItem('account'))
  const shortCutsData = [
    {
      icon: callForward,
      redirectTo: '/settings/personal/call_forwarding',
      title: 'Call Forwarding'

    },
    {
      icon: callManager,
      redirectTo: '/settings/users',
      title: 'Team Members'


    },
    {
      icon: callSettings,
      redirectTo: '/settings/platform/calls',
      title: 'Call Settings'


    },
    {
      icon: fromTracking,
      // redirectTo:'/settings/installation',
      openWindow: 'https://dialer.limephone.io/',
      title: 'Web Dialer'

    },
    {
      icon: qualificationSetting,
      redirectTo: '/settings/lead_qualification'
      ,
      title: 'Lead Qualification'
    },
    {
      icon: referFriend,
      openWindow: 'http://docs.limecall.com/',
      title: 'Knowledgebase'
    },
    {
      icon: rewards,
      redirectTo: '/settings/personal/notifications'
      ,
      title: 'Notification'
    },
    {
      icon: mobileAPP,
      redirectTo: ''
      ,
      title: 'Mobile App'
    },
  ]


  return (
    <div className="cardbox-wrapper deatil_box">
      <div className="container-trial">
        <div className="banner-description-wrapper planinfo-box row justify-content-start align-items-stretch">
          <div className="col-md-4 p-3">
            <div className="banner-description-box h-100 h-100">
              <h5>Account Info</h5>
              <p
                style={{
                  color:
                    data?.status === 'expired' ||
                    account === 4 ||
                    account === '4'
                      ? 'red'
                      : ''
                }}
              >
                {accountInfo
                  ? accountInfo
                  : account === 4
                  ? 'Your account is currently is currently paused.'
                  : data?.status === 'expired'
                  ? `Your active plan has expired`
                  : data?.status === 'trial_expired'
                  ? `Your trial has expired.Please pick a plan`
                  : `Your currently active plan is ${data?.plan_name || 'N/A'}`}
                {(data?.status !== 'Active' ||
                  account === 4 ||
                  account === '4') && (
                  <span
                    className="span-text cursor-pointer"
                    onClick={() => openSubscriptionModal()}
                  >
                    {' '}
                    {accountButtonText || 'Upgrade Plan'}
                  </span>
                )}
              </p>
              <div className="planinfo-box cursor-pointer">
                <p>Outbound calls/sms</p>
                <p>
                  {status && +status?.[0]?.outgoing_webhook?.status
                    ? 'Enabled'
                    : 'Disabled'}
                </p>
              </div>
              <div className="planinfo-box cursor-pointer">
                <p>Numbers</p>
                <p>
                  <Link to="/numbers">{data?.phone_numbers || 0}</Link>{' '}
                </p>
              </div>

              <div className="planinfo-box cursor-pointer">
                <p>Plan Status</p>
                <p>
                  <Link to="/manageleads">{data?.status}</Link>{' '}
                </p>
              </div>
              <div className="planinfo-box cursor-pointer">
                <Button basic onClick={() => setOpenCountry(!openCountry)}>
                  Whitelisted Countries
                </Button>
                <p></p>
              </div>
            </div>
          </div>

          {data?.plan_name === 'Free' && (
            <div className="col-md-4 p-3">
              <div className="banner-description-box h-100">
                <h5>Free plan</h5>
                <p>
                  You are currently on the free plan. Please upgrade your
                  subscription to enable phone forwarding, phone call-back,
                  virtual numbers, outbound calling/sms and much more.
                </p>

                <div className="text-center">
                  <Button
                    className="btn-color-green"
                    onClick={() => {
                      openSubscriptionModal && openSubscriptionModal()
                    }}
                  >
                    Upgrade Subscription
                  </Button>
                </div>
              </div>
            </div>
          )}
          {data?.status === 'in_trial' ? (
            <div className="col-md-4 p-3">
              <div className="banner-description-box h-100">
                <h5>Free Trial</h5>
                <p>Trial Restrictions</p>
                {data?.trial_end && (
                  <p>{`Your trial expires on ${moment(data?.trial_end)?.format(
                    'MMMM Do YYYY, h:mm a'
                  )}`}</p>
                )}
                <p>
                  You are currently on the {data?.plan_name} plan trial that
                  allow calls/text to usa numbers or web app only. To enable
                  other countries during your trial please contact us.
                </p>

                {/* <div className="text-center">
                  <Button
                    className="btn-color-green"
                    onClick={() => {
                      openSubscriptionModal && openSubscriptionModal()
                    }}
                  >
                    Upgrade Subscription
                  </Button>
                </div> */}
              </div>
            </div>
          ) : (
            (account === '4' || account === 4) && (
              <div className="col-md-4 p-3">
                <div className="banner-description-box h-100">
                  <h5>Account is currently limited.</h5>
                  <p>Usage limit hit</p>
                  {/* {data?.trial_end && <p>{`Your trial expires on ${moment(data?.trial_end)?.format('LT')}`}</p>} */}
                  <p>
                    You have exceeded the usage limit on leads and calls. Your
                    account currently cannot receive any leads or make calls.
                    Please upgrade your plan or contact support
                  </p>

                  {/* <div className="text-center">
                    <Button
                      className="btn-color-green"
                      onClick={() => {
                        openSubscriptionModal && openSubscriptionModal()
                      }}
                    >
                      Upgrade Subscription
                    </Button>
                  </div> */}
                </div>
              </div>
            )
          )}

          <div className="col-md-4 p-3">
            <div className="banner-description-box h-100">
              <div className="d-flex justify-content-between mb-3">
                <h5 className="mb-3">Quick Links</h5>
              </div>
              <Row>
                {shortCutsData?.map((obj, index) => (
                  <Col md={3} className="home-shortcuts">
                    <div
                      onClick={() =>
                        obj?.redirectTo === '/dialer'
                          ? getDialer(!dialerStatus)
                          : obj?.openWindow
                          ? window.open(obj?.openWindow)
                          : navigate.push(obj?.redirectTo)
                      }
                      className="shortcuts-icon"
                    >
                      <img src={obj?.icon} alt="dummy-icon" />
                    </div>
                    <p>{obj?.title}</p>
                  </Col>
                ))}
              </Row>
            </div>
          </div>

          {/* ways to use limephone */}
          {/* <div className='col-12'> 
      <h3 className='text-center fw-bolder'>Ways to use Limephone</h3>

         </div>

          <div className="col-md-4 p-3 ">
            <div className="banner-description-box h-100">
          <div style={{paddingBottom:'20px'}}>
          <Link  style={{fontWeight:'unset !important',fontSize:'17px'}}  to='/settings/installation'>Installation Instruction</Link>

          <div class="input-group input-group-sm mb-3 mt-3" >

<input type="text" class="form-control" style={{ background: 'white' }} readOnly placeholder={widgetData?.new_shareable_link} aria-label="Recipient's username" aria-describedby="button-addon2" />
<div class="input-group-prepend"  style={{maxHeight:'28px',height:'100%'}}>
  <button class="btn btn-outline-secondary" style={{ background: 'lightGray' }} type="button" onClick={() => onCopyText(widgetData?.new_shareable_link)} id="button-addon2"><FileCopyIcon /></button>
  <button class="btn btn-outline-primary" style={{ background: 'lightGray' }} type="button" onClick={() => window.open(widgetData?.new_shareable_link)} id="button-addon2"><LinkIcon /></button>

</div>
</div>
                  {/* <div>    
                  <div    
                  
                  style={{
                  display: '-webkit-inline-flex'
                }}>
                    {' '}
                
<a href={`https://app.limecall.com/widget/call-page/${localStorage?.getItem('widget_id')}`}>Test Web Widget</a>
                   

                   
            
         
       
                  </div>
            
                  
                  </div> 
                  <img src={installWidget} width={300} height={300} alt="personal"/>
                  </div>
                
                  
                
               
            </div>
          </div>
        

          <div className="col-md-4 p-3 ">
            <div className="banner-description-box h-100">
            <div style={{ paddingBottom: '20px' }}>
                <h5>Virtual Number</h5>
                {/* <a href={`numbers`} style={{fontWeight:'unset !important',fontSize:'19px'}}>Virtual Number</a> 
                <div>      <div

                  style={{
                    display: '-webkit-inline-flex'
                  }}>
                  {' '}

                  <a href={`${process.env.REACT_APP_ROOT_URL}/numbers`} target="_blank">Add a virtual number</a>



                </div>
                <br/>
                </div>


                  <img src={virtualNumber} width={300} height={300} alt="personal"/>
                  </div>
                
                  
                
               
            </div>
          </div>

          <div className="col-md-4 p-3 ">
            <div className="banner-description-box h-100">
          <div style={{paddingBottom:'20px'}}>
          <div className='d-flex justify-content-between mb-3'>
                <Link to='/numbers'  style={{fontWeight:'unset !important',fontSize:'17px'}}>Personal Booking Link</Link>
               
                </div>
               
                  <div>    
                  <div>

                  
                  <div class="input-group input-group-sm mb-3">

                    <input type="text" class="form-control" style={{ background: 'white' }} readOnly placeholder={profileData?.personal_link} aria-label="Recipient's username" aria-describedby="button-addon2" />
                    <div class="input-group-prepend"  style={{maxHeight:'28px',height:'100%'}}>
                      <button class="btn btn-outline-secondary" style={{ background: 'lightGray' }} type="button" onClick={() => onCopyText(profileData?.personal_link)} id="button-addon2"><FileCopyIcon /></button>
  <button class="btn btn-outline-primary" style={{ background: 'lightGray' }} type="button" onClick={() => window.open(widgetData?.personal_link)} id="button-addon2"><LinkIcon /></button>

                    </div>
                  </div>

                  <div class="input-group input-group-sm mb-3">
                    <input type="text" class="form-control" style={{ background: 'white' }}  readOnly placeholder={profileData?.new_personal_link} aria-label="Recipient's username" aria-describedby="button-addon2" />
                    <div class="input-group-prepend"  style={{maxHeight:'28px',height:'100%'}}>
                      <button class="btn btn-outline-secondary" style={{ background: 'lightGray' }} type="button" onClick={() => onCopyText(profileData?.new_personal_link)} id="button-addon2"><FileCopyIcon /></button>
  <button class="btn btn-outline-primary" style={{ background: 'lightGray' }} type="button" onClick={() => window.open(widgetData?.new_personal_link)} id="button-addon2"><LinkIcon /></button>
                    </div>
                  </div>

                </div>
            

                  </div>
                  <img src={user} width={300} height={300} alt="personal"/>
                  </div>
                
                  
                
               
            </div>
          </div> */}
          {/* <div className="col-md-4 p-3 ">
            <div className="banner-description-box h-100">
          <div style={{paddingBottom:'20px'}}>
                  <h5>Personal Booking Link</h5>
               
                  <div>    
                  <div    
                  
                  style={{
                  display: '-webkit-inline-flex'
                }}>
                    {' '}
                
              <a href={`${profileData?.personal_link}`} target="_blank">{profileData?.personal_link}</a> 
           
       
                  </div>
                  {' '}
                  <Icon
                      name="copy"
                      onClick={() =>onCopyText(profileData?.personal_link)}
                    />
                  </div>
                  </div>
                  <div style={{paddingBottom:'20px'}}>
                  <h5>Your Personal Landing Page</h5>
               
                  <div>    
                  <div    
                  
                  style={{
                  display: '-webkit-inline-flex'
                }}>
                    {' '}
                
              <a href={`${profileData?.new_personal_link}`} target="_blank">{profileData?.new_personal_link}</a> 
           
       
                  </div>
                  {' '}
                  <Icon
                      name="copy"
                      onClick={() =>onCopyText(profileData?.new_personal_link)}
                    />
                  </div>
                  </div>
                  <div>
                  <h5>Direct Call Link</h5>
                  <div className="text-end">
                    {' '}
                    <a href={widgetData?.new_shareable_link}>{widgetData?.new_shareable_link}</a> 
                  {' '}
                 <Icon
                      name="copy"
                      onClick={() =>onCopyText(widgetData?.new_shareable_link)}
                    />
                  </div>
                  </div>
                
                  
                
               
            </div>
          </div> */}
        </div>
      </div>
      {openCountry && (
        <CountryModal
          open={openCountry}
          isModalClose={() => setOpenCountry(!openCountry)}
        />
      )}
      {/* {statusModel && (
        <CheckStatus
          open={statusModel}
          isModalClose={() => setStatusModel(!statusModel)}
        />
      )} */}
    </div>
  )
}

const mapStateToProps = state => ({
  subscription: state.home.subscription,
  profileData: state.settings?.profile?.data,
  widgetData: state.home?.widget?.data?.[0],
  dialerStatus: state.dialer.currentStatus
  
})

export default connect(mapStateToProps, { getDialer })(PlanInfo)

