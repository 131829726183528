import React, { Component, useEffect, useState } from 'react'

import Title from '../common/Title'

import InnerNavigation from '../common/InnerNavigation'

import icon from '../assets/images/settingIcon.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Button, Popover } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

const titleContent = {
  type: 'image',
  titleOne: icon,
  titleTwo: 'Number'
}

const settingsData = [
  {
    mainSidebar: 'Manage Number',
    innerTabs: ['Manage Number']
  }
]

export const Numbers = props => {
  const [sideTab, setSideTab] = useState(0)
  const navigate = useHistory()
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopoverOpen = event => {
      setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
  useEffect(() => {
    document.title = 'Numbers'
  }, [])

  return (
    <div className="fullwidth-container number-container">
      <div className="sidebarWrap">
        <div
          className="meetingSidebar"
          style={{ left: '120px', top: '62px', paddingTop: '20px' }}
        >
          <label
            className={sideTab == 0 ? 'sideTab' : ''}
            onClick={() => {
              setSideTab(0)
            }}
          >
            You numbers
          </label>

          <label
            // className={sideTab == 2 ? 'sideTab' : ''}
            onClick={() => {
              navigate.push('/settings/kyc')
            }}
          >
            Addresses
          </label>
        </div>
        <div className="numbers-banner-container manage_number_main">
          {sideTab === 0 ? (
            <>
              <div className="numbers-banner justify-content-between">
                <div className="d-flex">
                  <div className="banner-icon">
                    <img src={icon} alt="icon" />
                  </div>
                  <div className="banner-description-wrapper">
                    <p
                      className="banner-description"
                      style={{ fontSize: '22px' }}
                    >
                      Manage your Virtual phone numbers.
                    </p>
                  </div>
                </div>

                <HelpOutlineOutlinedIcon
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  fontSize="large"
                />
                <Popover
                  id="mouse-over-popover"
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  // disableRestoreFocus
                >
                  <div
                    style={{ padding: '15px' }}
                    onMouseLeave={handlePopoverClose}
                  >
                    <div className="d-flex justify-content-between">
                      <h2>Need help?</h2>
                      <CloseIcon onClick={() => handlePopoverClose()} />
                    </div>
                    <p>
                      Watch a tour of managing virtual numbers or check out{' '}
                      <br />
                      our virtual number knowledgebase
                    </p>
                    <Button
                      variant="contained"
                      onClick={() =>
                        window.open('https://docs.limephone.io/video-guides')
                      }
                    >
                      Watch demo
                    </Button>
                    <Button
                      className="ml-3"
                      variant="outlined"
                      onClick={() =>
                        window?.open(
                          'https://docs.limephone.io/configure-your-number'
                        )
                      }
                    >
                      Knowledge base
                    </Button>
                  </div>
                </Popover>
              </div>

              <InnerNavigation
                dataComponent={settingsData}
                activeTab={props.activeTab}
                activeComponent={props.activeComponent}
                dataTitle={titleContent}
              />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default Numbers
