import axios from 'axios'
import React, { Component } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { logOut } from '../common/ProfileModal'
import NotificationSide from '../components/notification/NotificationSide'
import Configure from '../components/numbers/Configure'
import LocalNumbers from '../components/numbers/LocalNumbers'
import MobileNumber from '../components/numbers/MobileNumber'
import MyNumbers from '../components/numbers/MyNumbers'
import TollFreeNumbers from '../components/numbers/TollFreeNumbers'
import MySettingsAccount from '../components/settings/AccountSettings'
import AddConnections from '../components/settings/AddConnections'
import AddPersonalLink from '../components/settings/AddPersonalLink'
import ApiSettings from '../components/settings/ApiSettings'
import AuditLog from '../components/settings/AuditLog'
import BillingInfo from '../components/settings/BillingInfo'
import BlackListSettings from '../components/settings/BlackListSettings'
import BusinessWorkingHours from '../components/settings/BusinessWorkingHours'
import CallerIdSettings from '../components/settings/CallerIdSetting'
import CallForwarding from '../components/settings/CallForwarding'
import CallRoutingSettings from '../components/settings/CallRouting'
import CallScoreTagSettings from '../components/settings/CallScoreTags'
import MySettingsCalls from '../components/settings/CallSettings'
import CallTableDesignSettings from '../components/settings/CallTableDesign'
import CompanySettings from '../components/settings/CompanySettings'
import CountriesSettings from '../components/settings/CountriesSettings'
import InstallationSettings from '../components/settings/InstallationSettings'
import Integration from '../components/settings/Integration'
import Invoices from '../components/settings/InvoiceBilling'
import ManageTeammates from '../components/settings/ManageTeammates'
import MySettingsNotifications from '../components/settings/NotificationSettings'
import Paymentlog from '../components/settings/PaymentLog'
import PaymentMethod from '../components/settings/PaymentMethods'
import QuickReplies from '../components/settings/QuickReplies'
import Recentchargeslog from '../components/settings/RecentChargesLog'
import ScheduleSettings from '../components/settings/ScheduleSettings'
import ShareYourLinkSettings from '../components/settings/ShareYourLinkSettings'
import SmsSettings from '../components/settings/SmsSettings'
import SubscriptionSettings from '../components/settings/SubscriptionSettings'
import TeamSettings from '../components/settings/TeamSettings'
import Usage from '../components/settings/UsageBilling'
import WebHooksSettings from '../components/settings/WebHooksSettings'
// import rg4js from 'raygun4js'
import WebHooksURL from '../components/settings/WebHooksURL'
import WorkingHours from '../components/settings/WorkingHours'
import InnerSideBar from './InnerSidebar'
import InnerTabs from './InnerTabs'
import Title from './Title'
import ReferModal from '../common/ReferModal'
import KycDetail from '../components/settings/KycDetail'
import newKYCDetail from '../components/settings/newKYCDetail'


let widgetData = sessionStorage.getItem('widget')

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeComponent: this.props.settingActiveComponent
        ? this.props.settingActiveComponent
        : this.props.activeComponent,
      activeTab: this.props.settingActiveTab
        ? this.props.settingActiveTab
        : this.props.activeTab,
      widget: { id: 0, script: '', business_hours_status: null },

      isMenuOpen: false
    }

    // rg4js('apiKey', '71pSno4MdPm3xJPkPP7fGA')
    // rg4js('enablePulse', true)
    // rg4js('enableCrashReporting', true)
  }

  handleEventTrack = path => {
    return path
    // rg4js('trackEvent', { type: 'pageView', path: path })
  }

  handleLoading = state => {
    this.setState({
      isLoading: state
    })
  }

  optionRemove = () => {
    document.getElementsByTagName('body')[0].removeAttribute('class', '')
  }

  optionAdd = () => {
    document.getElementsByTagName('body')[0].setAttribute('class', 'noScroll')
  }

  fetchWidget = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/widgets`
    axios
      .get(url)
      .then(res => {
        if (res.data.data[0]) {
          let items = { ...this.state.widget }
          let tempObject = {
            id: res.data.data[0].id,
            script: res.data.data[0].script_id,
            business_hours_status: res.data.data[0].business_hours_status
          }
          localStorage.setItem('widget_active', res.data.data[0].active)
          localStorage.setItem('widget_id', res.data.data[0].id)
          items = tempObject
          this.setState({ ...this.state, widget: items })
          sessionStorage.setItem('widget', JSON.stringify(items))
        }
      })
      .catch(error => {
        if (error?.response?.status === 401) {
          logOut()
          return
        }
        if (!error?.response) {
        }
      })
  }

  componentWillMount = () => {
    const query = window?.location?.href
    const code = query?.split('state=')[1]?.split('&')[0]
    if (!!code) {
    }
    let widgetData = sessionStorage.getItem('widget')
    if (!widgetData) {
      this.fetchWidget()
    }
  }

  handleData = (data, initialTab, isMobile) => {
    if (isMobile) {
      this.setState({
        activeComponent: data
      })
    } else {
      this.setState({
        activeComponent: data,
        activeTab: initialTab
      })
    }
  }
  handleTabData = (tabData, updateURL) => {
    this.setState({ activeTab: tabData })

    const stacks = [
      {
        found: 'account',
        replace: 'personal'
      },
      {
        found: 'company',
        replace: 'company'
      },
      {
        found: 'users',
        replace: 'users'
      },
      {
        found: 'teams',
        replace: 'teams'
      },
      {
        found: 'installation',
        replace: 'installation'
      },
      {
        found: 'api',
        replace: 'api'
      },
      {
        found: 'integration',
        replace: 'integration'
      },
      {
        found: 'myPage',
        replace: 'myPage'
      }
    ]

    const itm = stacks.find(item => {
      if (
        item.found ==
        tabData
          .toLowerCase()
          .split(' ')
          .join('_')
      ) {
        let menuString =
          '/settings/' +
          item.replace
            .toLowerCase()
            .split(' ')
            .join('_')
        window.history.pushState('object or string', 'Title', menuString)
        this.handleEventTrack(menuString)
        document.title = this.state.activeComponent + ' - ' + tabData
        return true
      }
    })

    if (itm) return

    if (updateURL) {
      const activeComponent =
        this.state.activeComponent === 'Users&Teams'
          ? 'Users & Teams'
          : this.state.activeComponent
      let menuString =
        '/settings/' +
        activeComponent
          .toLowerCase()
          .split(' ')
          .join('_') +
        '/' +
        tabData
          .toLowerCase()
          .split(' ')
          .join('_')
      window.history.pushState('object or string', 'Title', menuString)
      this.handleEventTrack(menuString)
      document.title = this.state.activeComponent + ' - ' + tabData
    }
  }

  onMobileMenuHandler = () => {
    this.optionAdd()
    this.setState({
      isMenuOpen: true
    })
  }

  onMobileclickMenuHandler = () => {
    this.setState({
      isMenuOpen: false
    })
  }

  render() {
    const { dataComponent, dataTitle } = this.props
    // set by default active tabs to 'Personal
    if (this.state.activeComponent === 'MySettings') {
      this.setState({
        activeComponent: 'Personal'
      })
    }

    const locationPath = window.location.pathname
    const { activeComponent, activeTab } = this.state

    let dataInnerMenu = []
    let dataInnerTabs = []
    let dataInnerTabsMenu
    let matchedItem
    dataComponent.forEach(item => {
      dataInnerMenu.push(item.mainSidebar)
    })

    matchedItem = dataComponent.find(item => {
      if (item.mainSidebar) {
        return item.mainSidebar.replace(/\s/g, '') === activeComponent
      }

      if (activeTab == '') {
        //activeTab = item.defaultTab
      }
    })
    if (matchedItem) {
      dataInnerTabs = matchedItem.innerTabs
      dataInnerTabsMenu = matchedItem.innerTabs[0]
    }
    if (dataComponent && dataComponent.innerTabs) {
      dataInnerTabs = dataComponent.innerTabs
      dataInnerTabsMenu = dataComponent.innerTabs[0]
    }
    let ActiveTabComponent = MySettingsAccount
    if (activeComponent === 'Personal' && activeTab === 'Account') {
      ActiveTabComponent = MySettingsAccount
    } else if (
      activeComponent === 'Personal' &&
      activeTab === 'Notifications'
    ) {
      ActiveTabComponent = MySettingsNotifications
    } else if (activeComponent === 'LeadQualification') {
      ActiveTabComponent = CallScoreTagSettings
    } else if (activeComponent === 'Personal' && activeTab === 'Calendars') {
      ActiveTabComponent = AddConnections
    } else if (activeComponent === 'Company' && activeTab === 'Company') {
      ActiveTabComponent = CompanySettings

  } else if(activeComponent === 'KYC' && activeTab === 'KYC') {
  ActiveTabComponent = KycDetail
  
    } else if (activeComponent === 'Business' && activeTab === 'Business') {
  ActiveTabComponent = newKYCDetail
  }
    //  else if (
    //   activeComponent === 'Company' &&
    //   activeTab === 'Business Hours'
    // ) {
    //   ActiveTabComponent = ActiveTabComponent = BusinessWorkingHours
    // }
     else if (activeComponent === 'Company' && activeTab === 'Notifications') {
      ActiveTabComponent = NotificationSide
    } else if (activeComponent === 'Platform' && activeTab === 'Calls') {
      ActiveTabComponent = MySettingsCalls
    } else if (activeComponent === 'Personal' && activeTab === 'Schedules') {
      ActiveTabComponent = ScheduleSettings
    } else if (activeComponent === 'Platform' && activeTab === 'Countries') {
      ActiveTabComponent = CountriesSettings
    } else if (
      activeComponent === 'Platform' &&
      activeTab === 'Auto Response'
    ) {
      
      ActiveTabComponent = SmsSettings
    } else if (activeComponent === 'Users&Teams' && activeTab === 'Teams') {
      ActiveTabComponent = TeamSettings
    } else if (activeComponent === 'Users&Teams' && activeTab === 'Users') {
      ActiveTabComponent = ManageTeammates
    } else if (activeComponent === 'Platform') {
      if (activeTab === 'BlackList') {
        ActiveTabComponent = BlackListSettings
      } else if (activeTab === 'Caller Id') {
        ActiveTabComponent = CallerIdSettings
      } else if (
        activeComponent === 'Platform' &&
        activeTab === 'Lead Qualification'
      ) {
        ActiveTabComponent = CallScoreTagSettings
      } else if (activeTab === 'Call Routing') {
        ActiveTabComponent = CallRoutingSettings
      } else if (activeTab === 'Call Table Design') {
        ActiveTabComponent = CallTableDesignSettings
      }
    } else if (activeComponent === 'Billing' && activeTab === 'Invoices') {
      ActiveTabComponent = Invoices
    } else if (activeComponent === 'Billing' && activeTab === 'Payments') {
      ActiveTabComponent = Paymentlog
    } else if (
      activeComponent === 'Billing' &&
      activeTab === 'Recent Charges'
    ) {
      ActiveTabComponent = Recentchargeslog
    } else if (activeComponent === 'Billing' && activeTab === 'Account Details') {
      ActiveTabComponent = BillingInfo
    } else if (activeComponent === 'Billing' && activeTab === 'Usage') {
      ActiveTabComponent = Usage
    } else if (
      activeComponent === 'Billing' &&
      activeTab === 'Payment Methods'
    ) {
      ActiveTabComponent = PaymentMethod
    } else if (activeComponent === 'Billing' && activeTab === 'Subscription') {
      ActiveTabComponent = SubscriptionSettings
    } else if (
      activeComponent === 'Integration' &&
      activeTab === 'Integration'
    ) {
      ActiveTabComponent = Integration
    } else if (
      (activeComponent === 'CallForwarding' ||
        activeComponent === 'Personal') &&
      activeTab === 'Call Forwarding'
    ) {
      ActiveTabComponent = CallForwarding
    } else if (
      
      activeComponent === 'Referrals' 
  ) {
    ActiveTabComponent = ReferModal 
    } else if (activeTab === 'Working Hours') {
      ActiveTabComponent = WorkingHours
    } else if (activeComponent === 'Personal' && activeTab === 'My Page') {
      ActiveTabComponent = AddPersonalLink
    } else if (
      activeComponent === 'QuickReplies' ||
      activeTab === 'Quick Replies' ||
      activeComponent === 'Quick Replies'
    ) {
      ActiveTabComponent = QuickReplies
    } else if (
      activeComponent === 'AuditLog' ||
      activeTab === 'Audit Log' ||
      activeComponent === 'Audit Log'
    ) {
      ActiveTabComponent = AuditLog
    } else if (
      activeComponent === 'Manage Number' &&
      activeTab === 'Manage Number'
    ) {
      ActiveTabComponent = MyNumbers
    } else if (activeComponent === 'Configure' && activeTab === 'Configure') {
      ActiveTabComponent = Configure
    } else if (activeComponent === 'AddNumbers' && activeTab === 'Local') {
      ActiveTabComponent = LocalNumbers
    } else if (activeComponent === 'AddNumbers' && activeTab === 'TollFree') {
      ActiveTabComponent = TollFreeNumbers
    } else if (activeComponent === 'AddNumbers' && activeTab === 'Mobile') {
      ActiveTabComponent = MobileNumber
    } else if (activeComponent === 'API' && activeTab === 'API') {
      ActiveTabComponent = ApiSettings
    } else if (activeComponent === 'API' && activeTab === 'Inbound webhook') {
      ActiveTabComponent = WebHooksSettings
    } else if (
      activeComponent === 'API' &&
      activeTab?.toLowerCase() === 'outbound webhook'
    ) {
      ActiveTabComponent = WebHooksURL
    } else if (
      activeComponent === 'Installation' &&
      activeTab === 'Installation'
    ) {
      ActiveTabComponent = InstallationSettings
    } else if (
      activeComponent === 'Installation' &&
      activeTab === 'Your live call page link'
    ) {
      ActiveTabComponent = ShareYourLinkSettings
    }
    if (
      (activeComponent === 'My Page' && activeTab === 'My Page') ||
      activeComponent === 'MyPage'
    ) {
      ActiveTabComponent = AddPersonalLink
    }

    if (this.props?.paramId) {
      ActiveTabComponent = Configure
    }

    const handleRedirect = () => {
      setTimeout(() => {
        window.scrollBy(0, -130)
      }, 1100)
    }

    return (
      <div>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>

        <Title data={dataTitle} dataInvoice={this.state.activeTab} />
        <div className="dashboard-wrapper inner-main-contaner settings">
          <div
            className={`mobile_side_menu ${
              this.state.isMenuOpen ? 'open' : null
            } `}
          >
            <i className="fas fa-bars" onClick={this.onMobileMenuHandler}></i>
          </div>

          {dataComponent &&
            dataComponent[0] &&
            dataComponent[0].mainSidebar !== 'Manage Number' &&
            dataComponent[0].mainSidebar !== 'AddNumbers' &&
            dataComponent[0].mainSidebar !== 'SetCampaign' && (
              <InnerSideBar
                dataInnerMenu={dataComponent}
                tabs={dataInnerTabs}
                isMenuOpen={this.state.isMenuOpen}
                onMobileclickMenuHandler={this.onMobileclickMenuHandler}
                dataTabs={dataInnerTabsMenu}
                activeComponent={this.state.activeComponent}
                handleData={this.handleData}
                handleTabData={this.handleTabData}
                handleEventTrack={this.handleEventTrack}
              />
            )}
          <div className="tab-wrapper">
            {dataInnerTabs.length > 0 && (
              <InnerTabs
                tabs={dataInnerTabs}
                dataTabs={this.state.activeTab}
                handleTabData={this.handleTabData}
              />
            )}
            <div
              className={`activeComponent-wrapper ${locationPath.includes(
                'numbers'
              ) &&
                this.props.paramId &&
                'manage-number-wrapper'}`}
            >
              {this.props?.paramId && (
                <div className="manage-number-menu-sidebar">
                  <ul>
                    <li className="active" onClick={handleRedirect}>
                      <a href="#status">Phone Menu</a>
                    </li>
                    <li className="active" onClick={handleRedirect}>
                      <a href="#phone-status">Phone Status</a>
                    </li>
                    <li onClick={handleRedirect}>
                      <a href="#call-recording">Call Recording</a>
                    </li>
                    <li onClick={handleRedirect}>
                      <a href="#user">Users</a>
                    </li>
                    <li onClick={handleRedirect}>
                      <a href="#notes">Notes</a>
                    </li>
                    <li onClick={handleRedirect}>
                      <a href="#call-forwarding">Call Forwarding</a>
                    </li>
                    <li onClick={handleRedirect}>
                      <a href="#voicemail">Voice mail</a>
                    </li>
                    <li onClick={handleRedirect}>
                      <a href="#greeting-message">Greeting Message</a>
                    </li>
                    <li onClick={handleRedirect}>
                      <a href="#business-hours">Business Hours</a>
                    </li>
                    <li onClick={handleRedirect}>
                      <a href="#auto-response">Auto Response</a>
                    </li>
                  </ul>
                </div>
              )}

              <ActiveTabComponent
                widget={widgetData ? JSON.parse(widgetData) : this.state.widget}
                loading={this.handleLoading}
                paramId={this.props.paramId ? this.props.paramId : 0}
                activeTab={this.state.activeTab}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Settings
