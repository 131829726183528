import home from '../../assets/images/Icons-nav-home.svg'
import homeActive from '../../assets/images/home-black.svg'
import phone from '../../assets/images/Icons-nav-leads.png'
import phoneActive from '../../assets/images/phone-black.svg'
import conversation from '../../assets/images/conversations-icon.svg'
import myPhone from '../../assets/images/Icons-nav-meetings.svg'
import myPhoneActive from '../../assets/images/phone-black.svg'
import square from '../../assets/images/Icons-nav-widgets.svg'
import squareActive from '../../assets/images/square-black.svg'
import twoPerson from '../../assets/images/Icons-nav-meetings.svg'
import twoPersonActive from '../../assets/images/two-person-black.svg'
import settings from '../../assets/images/Icons-nav-settings.svg'
import settingsActive from '../../assets/images/settings-black.svg'
import analytics from '../../assets/images/analytics-white.svg'
import analyticsActive from '../../assets/images/analytics-black.svg'
import campaign from '../../assets/images/campaign-white.svg'
import analyticsCampaign from '../../assets/images/campaign-black.svg'
import ContactBook from '../../assets/images/ContactIcon.svg'
import MeetingIcon from '../../assets/images/meetingIcon.png';
import CardView from '../../assets/images/cardView.svg';
import Mynumber from '../../assets/images/Icons-nav-numbers.svg'
import notification from '../../assets/images/Icons-nav-notifications.svg'

export const menus = [
  // {
  //   handle: 'menuSettings',
  //   stateKey: 'activeSettings',
  //   url: '/get-started',
  //   inactiveIcon:home,
  //   activeIcon: homeActive,
  //   hoverName: 'Start'
  // },
  {
    handle: 'menuHome',
    stateKey: 'activeHome',
    url: '/home',
    inactiveIcon: home,
    activeIcon: homeActive,
    hoverName: 'Home'
  },
  
  {
    handle: 'menuPhone',
    stateKey: 'activePhone',
    url: '/leads',
    inactiveIcon: phone,
    activeIcon: phoneActive,
    hoverName: 'Call Logs'
  },
  {
    handle: 'menuNumbers',
    stateKey: 'activeNumbers',
    url: '/numbers',
    inactiveIcon: Mynumber,
    activeIcon: Mynumber,
    hoverName: 'Numbers'
  },
  {
    handle: 'activeMeeting',
    stateKey: 'activeMeeting',
    url: '/manageleads',
    inactiveIcon: MeetingIcon,
    activeIcon: MeetingIcon,
    hoverName: 'CRM',
  },
  // {
  //   handle: 'menuPipeline',
  //   stateKey: 'activePipeline',
  //   url: '/pipeline',
  //   inactiveIcon: CardView,
  //   activeIcon: CardView,
  //   hoverName: 'Pipeline'
  // },
 
  //  {
  //    handle: 'menuMyPhone',
  //    stateKey: 'activeMyPhone',
  //    url: '/my-leads',
  //    inactiveIcon: myPhone,
  //    activeIcon: myPhoneActive,
  //    hoverName: 'My Leads'
  //  },
  // {
  //   handle: 'menuSquare',
  //   stateKey: 'activeSquare',
  //   url: '/widgets',
  //   inactiveIcon: square,
  //   activeIcon: squareActive,
  //   hoverName: 'Widget'
  // },

  {
    handle: 'menuContact',
    stateKey: 'activeContact',
    url: '/contacts',
    inactiveIcon: ContactBook,
    activeIcon: squareActive,
    hoverName: 'Contacts'
  },
  {
    handle: 'menuCampaign',
    stateKey: 'activeCampaign',
    url: '/campaign',
    inactiveIcon: campaign,
    activeIcon: analyticsCampaign,
    hoverName: 'Campaign'
  },
  {
    handle: 'menuAnalytics',
    stateKey: 'activeAnalytics',
    url: '/analytics',
    inactiveIcon: analytics,
    activeIcon: analyticsActive,
    hoverName: 'Analytics'
  },
  {
    handle: 'menuSettings',
    stateKey: 'activeSettings',
    url: '/settings/personal',
    inactiveIcon: settings,
    activeIcon: settingsActive,
    hoverName: 'Settings'
  },
  // {
  //   handle: 'menuConversation',
  //   stateKey: 'activeConversation',
  //   url: '/conversation',
  //   inactiveIcon: conversation,
  //   activeIcon: conversation,
  //   hoverName: 'Inbox',
  //   showCount: true

  // },

]

export const Operatormenus = [
  {
    handle: 'menuHome',
    stateKey: 'activeHome',
    url: '/home',
    inactiveIcon: home,
    activeIcon: homeActive,
    hoverName: 'Home'
  },
  {
    handle: 'menuPhone',
    stateKey: 'activePhone',
    url: '/manageleads',
    inactiveIcon: phone,
    activeIcon: phoneActive,
    hoverName: 'Call Reports'
  },
  {
    handle: 'menuNumbers',
    stateKey: 'activeNumbers',
    url: '/numbers',
    inactiveIcon: Mynumber,
    activeIcon: Mynumber,
    hoverName: 'Numbers'
  },

  {
    handle: 'menuCampaign',
    stateKey: 'activeCampaign',
    url: '/campaign',
    inactiveIcon: campaign,
    activeIcon: analyticsCampaign,
    hoverName: 'Campaign'
  },
  {
    handle: 'menuCampaign',
    stateKey: 'activeCampaign',
    url: '/campaign',
    inactiveIcon: Mynumber,
    activeIcon: Mynumber,
    hoverName: 'Campaign'
  },
  {
    handle: 'menuAnalytics',
    stateKey: 'activeAnalytics',
    url: '/analytics',
    inactiveIcon: analytics,
    activeIcon: analyticsActive,
    hoverName: 'Analytics'
  },
  {
    handle: 'menuSettings',
    stateKey: 'activeSettings',
    url: '/settings/personal',
    inactiveIcon: settings,
    activeIcon: settingsActive,
    hoverName: 'Settings'
  }
]
